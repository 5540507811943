import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Flex,
  Box,
  Textarea,
} from "@chakra-ui/react";
import FormikYupInputField from "components/formik-yup-input-field/formik-yup-input-field";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
interface IBaseReportTemplateProps {
  onChange?: (newValue: BaseTemplateCreateReportInput) => void;
}
const BaseReportTemplate = (props: IBaseReportTemplateProps) => {
  const { onChange } = props;
  const validationSchema = Yup.object().shape({
    total_run_hour: Yup.number()
      .required("Total run hours is required")
      .min(0, "Total run hours must be a positive number"),
    total_load_hour: Yup.number()
      .required("Total load hours is required")
      .min(0, "Total load hours must be a positive number"),
    maintenance_hour: Yup.number()
      .required("Maintenance hours is required")
      .min(0, "Maintenance hours must be a positive number"),
    recommendations: Yup.string().required("Recommendations is required"),
    actions: Yup.string().required("Actions is required"),
  });
  const formik = useFormik<BaseTemplateCreateReportInput>({
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: {
      total_run_hour: 0,
      total_load_hour: 0,
      maintenance_hour: 0,
      recommendations: "",
      actions: "",
    },
    onSubmit: async (values) => {
      //const formData = new FormData();
      //formData.append("total_run_hour", formik.values.total_run_hour.toString());
      //createDevice(formData);
    },
    validationSchema: validationSchema,
  });
  const isErrorTotalRunHour =
    formik.touched.total_run_hour && formik.errors.total_run_hour;
  const isErrorTotalLoadHour =
    formik.touched.total_load_hour && formik.errors.total_load_hour;
  const isErrorMaintenanceHour =
    formik.touched.maintenance_hour && formik.errors.maintenance_hour;
  const isErrorRecommendations =
    formik.touched.recommendations && formik.errors.recommendations;
  const isErrorActions = formik.touched.actions && formik.errors.actions;
  // TODO : find cleaner solution
  useEffect(() => {
    onChange(formik.values);
    let timer1 = setTimeout(() => formik.validateForm(), 100);

    // this will clear Timeout
    // when component unmount like in willComponentUnmount
    // and show will not change to true
    return () => {
      clearTimeout(timer1);
    };
  }, [JSON.stringify(formik.values)]);
  return (
    <Box>
      <Flex direction={{ sm: "column", xl: "row" }} gap="15px" my="5px">
        <FormikYupInputField
          label="Total Run Hours : "
          type="number"
          formikField="total_run_hour"
          formikValue={formik.values.total_run_hour}
          yupValidationErrors={isErrorTotalRunHour}
          formikHandleChange={formik.handleChange}
          formikSetFieldTouched={formik.setFieldTouched}
          helperText="Enter the Number Of Total Run Hours."
        />
        <FormikYupInputField
          label="Total Load Hours : "
          type="number"
          formikField="total_load_hour"
          formikValue={formik.values.total_load_hour}
          yupValidationErrors={isErrorTotalLoadHour}
          formikHandleChange={formik.handleChange}
          formikSetFieldTouched={formik.setFieldTouched}
          helperText="Enter the Number Of Total Load Hours."
        />
      </Flex>
      <Flex direction={{ sm: "column", xl: "row" }} gap="15px" my="5px">
        <FormikYupInputField
          label="Maintenance Hours : "
          type="number"
          formikField="maintenance_hour"
          formikValue={formik.values.maintenance_hour}
          yupValidationErrors={isErrorMaintenanceHour}
          formikHandleChange={formik.handleChange}
          formikSetFieldTouched={formik.setFieldTouched}
          helperText="Enter the Number Of Maintenance Hours."
        />
      </Flex>
      <Flex direction={{ sm: "column", xl: "row" }} gap="15px" my="5px">
        <FormikYupInputField
          InputElement={Textarea}
          label="Actions : "
          type="text"
          formikField="actions"
          formikValue={formik.values.actions}
          yupValidationErrors={isErrorActions}
          formikHandleChange={formik.handleChange}
          formikSetFieldTouched={formik.setFieldTouched}
          helperText="Enter the Actions You Do!."
        />
      </Flex>
      <Flex direction={{ sm: "column", xl: "row" }} gap="15px" my="5px">
        <FormikYupInputField
          InputElement={Textarea}
          label="Recommendations : "
          type="text"
          formikField="recommendations"
          formikValue={formik.values.recommendations}
          yupValidationErrors={isErrorRecommendations}
          formikHandleChange={formik.handleChange}
          formikSetFieldTouched={formik.setFieldTouched}
          helperText="Enter Your Recommendations To Customer."
        />
      </Flex>
    </Box>
  );
  /*return (
    <form onSubmit={formik.handleSubmit}>
      <input
        type="number"
        name="total_run_hour"
        onChange={formik.handleChange}
        value={formik.values.total_run_hour}
      />
      {formik.touched.total_run_hour && formik.errors.total_run_hour ? (
        <div>{formik.errors.total_run_hour}</div>
      ) : null}

      <button type="submit" disabled={!formik.isValid}>
        Submit
      </button>
    </form>
  );*/
};
export default BaseReportTemplate;
