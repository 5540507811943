
export enum LOCAL_STORAGE_KEYS {
  ACCESS_TOKEN = "AUTH_ACCESS_TOKEN",
  REFRESH_TOKEN = "AUTH_REFRESH_TOKEN",
  EXPIRES_IN = "AUTH_EXPIRES_IN",
  USER_TYPE = "USER_RULE_TYPE",
  USER_ID = "USER_ID",
  USER_NAME = "USER_NAME",
}

export type USER_RULE_TYPE = "ADMIN" | "CLIENT" | "TECHNICAL";

export const orderStateOptions = [
  "INITIALIZED",
  "ACCEPTED",
  "REJECTED",
  "DELETE",
  "CLOSED",
];

export const validDeviceNames = [
  "KAESER Screw Compressor",
  "KAESER Oil Free Screw Compressor",
  "KAESER Dryer",
  "KAESER Blower",
  "KAESER Booster",
  "KAESER Condensate Drain",
  "KAESER Filter"
]


export const choicesQuestionOptions = [
  "Bad",
  "Acceptable",
  "Good",
  "Very Good",
  "Excellent"
]