import { Grid, GridItem, Center, Divider, Text, Box } from "@chakra-ui/react";
import { formalizeCostDetails, toPascalCase } from "utilities/utilities";

interface OrderInfoTabProps {
  order?: Order;
}
const OrderInfoTab = (props: OrderInfoTabProps) => {
  const { order } = props;
  if (!order) {
    return (
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <div>There Are No Images Uploaded For This Device By Customer</div>
      </Box>
    );
  }
  return (
    <Grid gap={4}>
      <GridItem colSpan={4}>
        <Text as="b">Discription : </Text>
        <br />
        {order.description != "" ? order.description : "None"}
      </GridItem>
      <GridItem colSpan={2}>
        <Text as="b">Frequency Of Occurrence : </Text>
        <br />
        {order.frequency_of_occurrence != 0
          ? order.frequency_of_occurrence
          : "None"}
      </GridItem>
      <GridItem colSpan={2}>
        <Text as="b">Date Occurrence : </Text>
        <br />
        {order.date_occurrence
          ? new Date(order.date_occurrence).toLocaleString()
          : "None"}
      </GridItem>
      <GridItem colSpan={4}>
        <Center>
          <Divider w={"80%"} borderColor={"#000000"} orientation="horizontal" />
        </Center>
      </GridItem>

      <GridItem colSpan={2}>
        <Text as="b">Client : </Text> {order.client.name}
        {/* // TODO : Add Expand  */}
      </GridItem>
      <GridItem colSpan={2}>
        <Text as="b">Work Status : </Text> {order.order_work_status}
        {/* // TODO : Add Expand  */}
      </GridItem>
      <GridItem colSpan={2}>
        <Text as="b">Device : </Text> {order.device.name} (
        {toPascalCase(order.device_status)}){/* // TODO : Add Expand  */}
      </GridItem>
      <GridItem colSpan={2}>
        <Text as="b">Order State : </Text> {toPascalCase(order.state)}
      </GridItem>
      <GridItem colSpan={4}>
        <Text as="b">Order Contact : </Text>
        <br />
        {!order.order_contact || order.order_contact.length == 0
          ? "- None"
          : order.order_contact.map((oc) => (
              <>
                {" "}
                - <Text as="i">Name</Text> : {oc.contact_name},{" "}
                <Text as="i">Location</Text>: {oc.location},{" "}
                <Text as="i">Visit Date : </Text> {oc.visit_date} <br />
                {/* TODO : add phone number from backend */}
              </>
            ))}
      </GridItem>
      <GridItem colSpan={4}>
        <Text as="b">Technical : </Text>
        <br />
        {!order.technical || order.technical.length == 0
          ? "- None"
          : order.technical.map((t) => (
              <>
                {" "}
                - <Text as="i">Name</Text> : {t.name}
                <br />
              </>
            ))}
      </GridItem>
      <GridItem colSpan={4}>
        <Text as="b">Service Details : </Text> <br />
        {order.service_details ? (
          <>
            <Text as="i">Service Type :</Text>{" "}
            {order.service_details.service_type != ""
              ? order.service_details.service_type
              : "None"}{" "}
            <br />
            <Text as="i"> Report Type :</Text>{" "}
            {order.service_details.service_report_type != ""
              ? order.service_details.service_report_type
              : "None"}
            <br />
            <Text as="i"> Cost Details :</Text>
            {order.service_details.cost_details != null ||
            order.service_details.cost_details.props.length == 0
              ? formalizeCostDetails(order.service_details.cost_details)
              : "No Cost Details"}
          </>
        ) : (
          // TODO : add cost details
          "- None"
        )}
      </GridItem>
    </Grid>
  );
};

export default OrderInfoTab;
