import { Box, Flex } from "@chakra-ui/react";
import { PaginationState } from "common/models";
import OrdersTable from "components/data-tables/orders-table";
import { TableCardSkelton } from "components/skeltons/table-card-skelton";
import useOrders from "hooks/data-hooks/useOrders";
import * as React from "react";
import { useState } from "react";

export interface IOrdersProps {}

export default function Orders(props: IOrdersProps) {
  // pagination states
  const [ordersPageState, setOrdersPageState] = useState<PaginationState>({
    page: 1,
    perPage: 20,
    count: 0,
  });

  // filter order states
  const [selectedOrderStates, setSelectedOrderStates] = useState(["ACCEPTED", "REJECTED", "DELETE", "CLOSED"]);
  const [selectedClient, setSelectedClient] = useState<ClientLite>(undefined);

  // fetching date using useDevice custom hook

  const { orders, isLoadingOrders, isErrorOrders, loadingOrdersError, refetchOrders } = useOrders({
    page: ordersPageState.page,
    pageSize: ordersPageState.perPage,
    orderStates: selectedOrderStates,
    clientId: selectedClient?.id,
    onSuccess: (data) => setOrdersPageState({ ...ordersPageState, count: data.count }),
  });

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex align="center" gap={"10px"} direction={{ base: "column", md: "column" }}>
        {isLoadingOrders ? (
          <TableCardSkelton />
        ) : isErrorOrders ? (
          <TableCardSkelton
            message={loadingOrdersError?.response?.data?.message ?? "Un Expected Error Happen While Loading Orders!"}
            isErrorSkelton={true}
          />
        ) : (
          <OrdersTable
            selectedOrderStates={selectedOrderStates}
            setSelectedOrderStates={setSelectedOrderStates}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
            tableData={
              orders?.results?.map((r) => ({
                id: r.id,
                title: r.title,
                state: r.state,
                description: r.description,
                client: r.client, //.name,
                device: r.device.name, //.name,
                order_number: r.order_number,
                order_contact: r.order_contact,
                technical: r.technical,
                fullObject: r,
              })) || []
            }
            pagination={ordersPageState}
            setPagination={setOrdersPageState}
            isLoading={isLoadingOrders}
          />
        )}
      </Flex>
    </Box>
  );
}
