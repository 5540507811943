import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";



interface IUseProfileProps<TProfile> {
    actor: Actor,
    userId: string,
    onEditUserSuccess?: () => void,
    onEditUserFailed?: (error:AxiosError) => void,
    onLoadSuccess?: (profile: TProfile) => void,
}

/**
 * 
 * @param actor 
 * @param userId 
 * @returns 
 */
const fetchProfile = async <TUser>(actor: Actor, userId: string) => {
    const urlSubPath = actor == "technical" ? 'technical_profile' : 'client_profile';
    return (await axios.get<TUser>(`user/${urlSubPath}/${userId}/`)).data;
};

/**
 * 
 * @param actor 
 * @param userId 
 * @param input 
 * @returns 
 */
const editProfile = async <TUserUpdateInput>(actor: Actor, userId: string, input: TUserUpdateInput) => {
    const urlSubPath = actor == "technical" ? 'technical_profile' : 'client_profile';
    return (await axios.put<void, AxiosResponse<void, any>, TUserUpdateInput>(`user/${urlSubPath}/${userId}/`, input)).data;
};


/**
 * 
 */
const useProfile = <TProfile, TUpdateProfileInput>(props: IUseProfileProps<TProfile>) => {
    // deconstruction props
    const { actor, userId, onLoadSuccess, onEditUserSuccess, onEditUserFailed } = props;

    // Define the mutation function outside of useMutation
    const editProfileMutation = async (input: TUpdateProfileInput) => {
        return editProfile<TUpdateProfileInput>(actor, userId, input);
    };

    // profile fetching hook
    const { data: profile, isLoading: isLoadingProfile, isError: isErrorLoadingProfile } = useQuery(['profile', actor, userId], () => fetchProfile<TProfile>(actor, userId), { enabled: userId != undefined, onSuccess: onLoadSuccess, refetchOnWindowFocus: false })

    // edit profile hook
    const { mutate: edit, isLoading: isEdittingProfile, isError: isErrorEdittingProfile } = useMutation(editProfileMutation, {
        onSuccess: onEditUserSuccess,
        onError: onEditUserFailed
    })
    return { profile, isLoadingProfile, isErrorLoadingProfile, edit, isEdittingProfile, isErrorEdittingProfile };
};

export default useProfile;