// Chakra imports
import { Flex, Text } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";
import logo from './logo.png';

export function SidebarBrand() {

  return (
    <Flex alignItems="center" flexDirection="column">
      <Flex direction={"row"} gap={"10px"}>
        {/* <MastercardIcon h="26px" my="32px" color={logoColor} /> */}
        <img
          width={"40px"}
          height={"40px"}
          src={logo}
          alt=""
        />
        <Text
          color="secondaryGray.600"
          fontSize="2xl"
          fontWeight="900"
          me="12px"
        >
          Hamsie Services
        </Text>
      </Flex>
      <HSeparator mb="20px" mt={"20px"} />
    </Flex>
  );
}

export default SidebarBrand;
