import { Box, Flex } from "@chakra-ui/react";
import { PaginationState } from "common/models";
import DevicesTable from "components/data-tables/devices-table";
import { TableCardSkelton } from "components/skeltons/table-card-skelton";
import useDevices from "hooks/data-hooks/useDevices";
import * as React from "react";
import { useState } from "react";

export interface IMachinesProps {}

export default function Machines(props: IMachinesProps) {
  const [devicesPageState, setDevicesPageState] = useState<PaginationState>({
    page: 1,
    perPage: 20,
    count: 0,
  });
  const { devices, isLoadingDevices, isErrorLoadingDevices, loadingDevicesError } = useDevices({
    actor: "admin",
    page: devicesPageState.page,
    pageSize: devicesPageState.perPage,
    onLoadDevicesSuccess: (data) => setDevicesPageState({ ...devicesPageState, count: data.count }),
  });
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex align="center" gap={"10px"} direction={{ base: "column", md: "column" }}>
        {isLoadingDevices ? (
          <TableCardSkelton />
        ) : isErrorLoadingDevices ? (
          <TableCardSkelton
            message={loadingDevicesError?.response?.data?.message ?? "Un Expected Error Happen While Loading Devices!"}
            isErrorSkelton={true}
          />
        ) : (
          <DevicesTable
            tableData={
              devices?.results?.map((r) => ({
                image: r.device_images?.at(0)?.image,
                name: r.device_name,
                type: r.device_type,
                model: r.device_model,
                number_of_orders: r.order?.length ?? 0,
                tsgId: r.troubleshooting_guid?.id,
                fullObject: r,
              })) || []
            }
            pagination={devicesPageState}
            setPagination={setDevicesPageState}
            isLoading={isLoadingDevices}
          />
        )}
      </Flex>
    </Box>
  );
}
