import { Box, Button, Stack, Text } from "@chakra-ui/react";
import useAuth from "hooks/useAuth";
import * as React from "react";
import { Link } from "react-router-dom";

export interface IWelcomePageProps {}

export default function WelcomePage(props: IWelcomePageProps) {
  const auth = useAuth();
  return (
    <Box
      mt={{ base: "130px", md: "80px", xl: "80px" }}
      padding={"20px"}
      backgroundColor={"white"}
      height={"82vh"}
      borderRadius={"2%"}
    >
      <Stack justifyContent={"start"} height={"100%"}>
        <Text fontSize={"2rem"}>
          Welcome{" "}
          <Text as="b" fontSize={"2rem"}>
            {auth.getUser().username}
          </Text>
        </Text>
        <Text>
          Welcome to our platform, Hamsie services is here to help you organize your orders and save your data
        </Text>
        <Link to={"/client/overview"}>
          <Button variant="brand" fontWeight="500">
            Go To Your Updates
          </Button>
        </Link>
      </Stack>
    </Box>
  );
}
