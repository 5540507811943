import axios from "axios";
import { useMutation } from "react-query";

export const patchOrder = async (data: PatchOrderInput) => {
    const id = data.id;
    delete data["id"];
    await axios.patch(
        `orders/${id}/`,
        data
    );
};

interface IUseOrderUpdaterProps {
    onError?: () => void;
    onSuccess?: () => void;
}
/**
 *
 */
const useOrderUpdater = (props: IUseOrderUpdaterProps) => {
    const { onError, onSuccess } = props;

    const {
        mutate: update,
        isLoading: isUpdatingOrder,
        isError: isErrorUpdatingOrder,
    } = useMutation(patchOrder, {
        onError: onError,
        onSuccess: onSuccess,
    });
    return { update, isUpdatingOrder, isErrorUpdatingOrder, };
};

export default useOrderUpdater;
