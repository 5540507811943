import { Route } from "react-router-dom";

export const getRoutes = (routes: RoutesType[], path: string): any => {
  return routes.map((route: RoutesType, key: any) => {
    if (route.layout === path) {
      // substring to remove '/' from route path 
      return <Route path={route.path.substring(1)} element={route.component} key={key} />;
    } else {
      return null;
    }
  });
};
