import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import useProfile from "hooks/data-hooks/useProfile";
import useAuth from "hooks/useAuth";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { formalizeError } from "utilities/utilities";
function Profile() {
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColor = useColorModeValue("navy.700", "white");
  const auth = useAuth();
  const {
    profile,
    isLoadingProfile,
    isErrorLoadingProfile,
    edit,
    isEdittingProfile,
  } = useProfile<TechnicalProfile, TechnicalProfile>({
    userId: auth.getUser().pk,
    actor: "technical",
    onEditUserSuccess: () => {
      toast.success("Profile Edited Successfully.");
    },
    onEditUserFailed: (error) => {
      const errorData = error.response?.data as { [key: string]: string[] };
      toast.error("Profile Editing Failed! :\n " + formalizeError(errorData));
    },
    onLoadSuccess: (profile) => {
      setUserData(profile);
    },
  });
  const [userData, setUserData] = useState<TechnicalProfile>();
  if (isErrorLoadingProfile) {
    return <>Error While Loading Profile!</>;
  }
  if (isLoadingProfile) {
    return <>Loading....</>;
  }
  const disabled = isLoadingProfile || isEdittingProfile;
  return (
    <>
      <Box pt={{ base: "50px", md: "0px" }} mt={{ xl: "70px" }}>
        <Card mb={{ base: "0px", "2xl": "20px" }}>
          {/* <Flex justifyContent={"space-between"}>
            <Text color={textColor} fontSize="2xl" ms="24px" fontWeight="700">
              Your profile information :
            </Text>

            <Button onClick={() => setDisabled(!disabled)}>Edit</Button>
          </Flex> */}
          <FormControl>
            <Flex direction={{ sm: "column", xl: "row" }} gap="15px">
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  domain<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  disabled={disabled}
                  value={userData?.domain}
                  onChange={(e) => {
                    setUserData({
                      ...userData,
                      domain: e.target.value,
                    });
                  }}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                />
              </FormControl>{" "}
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  address<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  value={userData?.phone_number}
                  onChange={(e) => {
                    setUserData({
                      ...userData,
                      phone_number: e.target.value,
                    });
                  }}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                />
              </FormControl>
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  address<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  disabled={disabled}
                  value={userData?.address}
                  onChange={(e) => {
                    setUserData({
                      ...userData,
                      address: e.target.value,
                    });
                  }}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                />
              </FormControl>
            </Flex>{" "}
            <Button
              disabled={
                disabled ||
                JSON.stringify(profile ?? {}) === JSON.stringify(userData ?? {})
              }
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={() => edit(userData)}
            >
              Update Your Infos
            </Button>
          </FormControl>
          <Flex
            direction={{ base: "row", md: "row" }}
            justifyContent={"space-between"}
          ></Flex>
        </Card>
      </Box>
    </>
  );
}
export { Profile };
