import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  useColorModeValue,
  Text,
  Button,
  Table,
  Tr,
  Thead,
  Tbody,
  Td,
  Th,
  Image,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";

import { TbReport, TbFileInfo, TbPrinter } from "react-icons/tb";
import { ImHistory } from "react-icons/im";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { PaginationState } from "common/models";
import Card from "components/card/Card";
import Pagination from "components/Pagination/Pagination";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import DeviceModal from "../modals/device-modal/device-modal";
import { exportToExcel } from "utilities/utilities";
interface CustomersTableProps {
  viewAllMachinesRoute?: string;
  tableData: DeviceRow[];
  pagination: PaginationState;
  setPagination: (newState: PaginationState) => void;
  isLoading: boolean;
  hasPagination?: boolean;
}
type DeviceRow = {
  image?: string;
  name: string;
  type: string;
  model: string;
  number_of_orders: number;
  tsgId?: string;
  fullObject: Device;
};
const columnHelper = createColumnHelper<DeviceRow>();
const DevicesTable = (props: CustomersTableProps) => {
  const { hasPagination = true } = props;
  const data = props.tableData;
  const { isOpen: isOpenExpandModal, onOpen: onOpenExpandModal, onClose: onCloseExpandModal } = useDisclosure();
  const [expandedDevice, setExpandedDevice] = React.useState<Device>(null);
  const navigate = useNavigate();

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const acceptButton = useColorModeValue("green.500", "whiteAlpha.100");
  const rejectButton = useColorModeValue("red.500", "whiteAlpha.100");
  // let defaultData = tableData;
  const columns = [
    columnHelper.accessor("image", {
      id: "image",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Image
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Image
            maxWidth={"10vh"}
            style={{
              aspectRatio: "1/1",
              boxShadow: "0px 0px 10px 3px #ffffff",
              borderRadius: "50%",
              border: !info.getValue() ? "2px solid black" : null,
            }}
            src={info.getValue()}
            alt={"Device Image"}
          />
        </Flex>
      ),
    }),
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Name
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("type", {
      id: "website",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Type
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("model", {
      id: "model",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Model
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("number_of_orders", {
      id: "number_of_orders",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Number Of Orders
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("tsgId", {
      id: "tsgId",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          History
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          <IconButton
            aria-label="Search database"
            size="lg"
            fontSize={"3vh"}
            icon={<ImHistory />}
            disabled={!info.getValue()}
            onClick={() => {
              navigate(`../device-history/${info.getValue()}`);
            }}
          />
        </Text>
      ),
    }),
    columnHelper.accessor("fullObject", {
      id: "fullObject",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Actions
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          <IconButton
            aria-label="Expand Device"
            size="lg"
            fontSize={"3vh"}
            icon={<TbFileInfo color="#1b2559" size={"4vh"} />}
            onClick={() => {
              setExpandedDevice(info.getValue());
              onOpenExpandModal();
            }}
          />
          <IconButton
            aria-label="Print Device"
            size="lg"
            fontSize={"3vh"}
            icon={<TbPrinter color="#1b2559" size={"4vh"} />}
            onClick={() => {
              navigate("../print-device", { state: info.getValue() });
            }}
          />
        </Text>
      ),
    }),
  ];
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text color={textColor} fontSize="22px" mb="4px" fontWeight="700" lineHeight="100%">
          Devices Table
        </Text>
        {props.viewAllMachinesRoute && (
          <Link to={props.viewAllMachinesRoute}>
            <Button>View All Machines</Button>
          </Link>
        )}
        <Button onClick={() => exportToExcel(props.tableData)}>Export As Excel</Button>
      </Flex>
      <DeviceModal device={expandedDevice} isOpen={isOpenExpandModal} onClose={onCloseExpandModal} />

      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {props.tableData?.length == 0 ? (
              <Tr>
                <Td colSpan={7} style={{ textAlign: "center", color: "black", fontSize: "1.2rem", padding: "4rem" }}>
                  You Have Not Any Device (Machine) Yet!
                </Td>
              </Tr>
            ) : (
              table.getRowModel().rows.map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
        {hasPagination && (
          <Pagination
            currentPage={props.pagination.page}
            itemsPerPage={props.pagination.perPage}
            paginate={(val) => {
              props.setPagination({
                ...props.pagination,
                page: val.page,
                perPage: val.perPage,
              });
            }}
            totalItems={props.pagination.count}
            disabled={props.isLoading}
          />
        )}
      </Box>
    </Card>
  );
};

export default DevicesTable;
