import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import TextFiled from "components/textFiled/TextFiled";
import useProfile from "hooks/data-hooks/useProfile";
import useAuth from "hooks/useAuth";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { formalizeError } from "utilities/utilities";

function Profile() {
  const auth = useAuth();
  const {
    profile,
    isLoadingProfile,
    isErrorLoadingProfile,
    edit,
    isEdittingProfile,
  } = useProfile<CustomerProfile, CustomerProfile>({
    userId: auth.getUser().pk,
    actor: "client",
    onEditUserSuccess: () => {
      toast.success("Profile Edited Successfully.");
    },
    onEditUserFailed: (error) => {
      const errorData = error.response?.data as { [key: string]: string[] };
      toast.error("Profile Editing Failed! :\n " + formalizeError(errorData));
    },
    onLoadSuccess: (profile) => {
      setUserData(profile);
    },
  });
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColor = useColorModeValue("navy.700", "white");
  const [userData, setUserData] = useState<CustomerProfile>();
  if (isErrorLoadingProfile) {
    return <>Error While Loading Profile!</>;
  }
  if (isLoadingProfile) {
    return <>Loading....</>;
  }
  const disabled = isLoadingProfile || isEdittingProfile;

  return (
    <>
      <Box pt={{ base: "50px", md: "0px" }} mt={{ xl: "70px" }}>
        <Card mb={{ base: "0px", "2xl": "20px" }}>
          {/* <Flex justifyContent={"space-between"}>
            <Text color={textColor} fontSize="2xl" fontWeight="700">
              Your profile information :
            </Text>

            <Button onClick={() => setDisabled(!disabled)}>Edit</Button>
          </Flex> */}
          <FormControl>
            <Flex direction={{ sm: "column", xl: "row" }} gap="15px">
              <TextFiled
                value={userData?.email}
                onChange={(e: any) => {
                  setUserData({
                    ...userData,
                    email: e.target.value,
                  });
                }}
                label="Email"
                isDisabled={disabled}
                textColor={textColor}
                brandStars={brandStars}
                isRequired={false}
              />
              <TextFiled
                value={userData?.address}
                onChange={(e: any) => {
                  setUserData({
                    ...userData,
                    address: e.target.value,
                  });
                }}
                label="address"
                isDisabled={disabled}
                textColor={textColor}
                brandStars={brandStars}
                isRequired={true}
              />
              <TextFiled
                value={userData?.company_name}
                onChange={(e: any) => {
                  setUserData({
                    ...userData,
                    company_name: e.target.value,
                  });
                }}
                label="company_name"
                isDisabled={disabled}
                textColor={textColor}
                brandStars={brandStars}
                isRequired={true}
              />
            </Flex>
            <Flex direction={{ sm: "column", xl: "row" }} gap="15px">
              <TextFiled
                value={userData?.facebook}
                onChange={(e: any) => {
                  setUserData({
                    ...userData,
                    facebook: e.target.value,
                  });
                }}
                label="facebook"
                isDisabled={disabled}
                textColor={textColor}
                brandStars={brandStars}
                isRequired={true}
              />
              <TextFiled
                value={userData?.manager}
                onChange={(e: any) => {
                  setUserData({
                    ...userData,
                    manager: e.target.value,
                  });
                }}
                label="manager"
                isDisabled={disabled}
                textColor={textColor}
                brandStars={brandStars}
                isRequired={true}
              />
              <TextFiled
                value={userData?.phone_number}
                onChange={(e: any) => {
                  setUserData({
                    ...userData,
                    phone_number: e.target.value,
                  });
                }}
                label="phone number"
                isDisabled={disabled}
                textColor={textColor}
                brandStars={brandStars}
                isRequired={true}
              />
            </Flex>
            <Flex direction={{ sm: "column", xl: "row" }} gap="15px">
              <TextFiled
                value={userData?.technical_manger}
                onChange={(e: any) => {
                  setUserData({
                    ...userData,
                    technical_manger: e.target.value,
                  });
                }}
                label="technical manager"
                isDisabled={disabled}
                textColor={textColor}
                brandStars={brandStars}
                isRequired={true}
              />
              <TextFiled
                value={userData?.website}
                onChange={(e: any) => {
                  setUserData({
                    ...userData,
                    website: e.target.value,
                  });
                }}
                label="website"
                isDisabled={disabled}
                textColor={textColor}
                brandStars={brandStars}
                isRequired={true}
              />
            </Flex>
            <Flex direction={{ sm: "column", xl: "row" }} gap="15px">
              {/* Existing TextFiled components */}
              <TextFiled
                value={userData?.landline_telephhone}
                onChange={(e: any) => {
                  setUserData({
                    ...userData,
                    landline_telephhone: e.target.value,
                  });
                }}
                label="landline telephone"
                isDisabled={disabled}
                textColor={textColor}
                brandStars={brandStars}
                isRequired={true}
              />
              <TextFiled
                value={userData?.manager_mobile}
                onChange={(e: any) => {
                  setUserData({
                    ...userData,
                    manager_mobile: e.target.value,
                  });
                }}
                label="manager mobile"
                isDisabled={disabled}
                textColor={textColor}
                brandStars={brandStars}
                isRequired={true}
              />
              <TextFiled
                value={userData?.manager_email}
                onChange={(e: any) => {
                  setUserData({
                    ...userData,
                    manager_email: e.target.value,
                  });
                }}
                label="manager email"
                isDisabled={disabled}
                textColor={textColor}
                brandStars={brandStars}
                isRequired={true}
              />
            </Flex>
            <Flex direction={{ sm: "column", xl: "row" }} gap="15px">
              <TextFiled
                value={userData?.technician_name}
                onChange={(e: any) => {
                  setUserData({
                    ...userData,
                    technician_name: e.target.value,
                  });
                }}
                label="technician name"
                isDisabled={disabled}
                textColor={textColor}
                brandStars={brandStars}
                isRequired={true}
              />
              <TextFiled
                value={userData?.technician_mobile}
                onChange={(e: any) => {
                  setUserData({
                    ...userData,
                    technician_mobile: e.target.value,
                  });
                }}
                label="technician mobile"
                isDisabled={disabled}
                textColor={textColor}
                brandStars={brandStars}
                isRequired={true}
              />
              <TextFiled
                value={userData?.technician_email}
                onChange={(e: any) => {
                  setUserData({
                    ...userData,
                    technician_email: e.target.value,
                  });
                }}
                label="technician email"
                isDisabled={disabled}
                textColor={textColor}
                brandStars={brandStars}
                isRequired={true}
              />
            </Flex>
            <Button
              disabled={
                disabled ||
                JSON.stringify(profile ?? {}) === JSON.stringify(userData ?? {})
              }
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={() => edit(userData)}
            >
              Update Your Infos
            </Button>
          </FormControl>
          <Flex
            direction={{ base: "row", md: "row" }}
            justifyContent={"space-between"}
          ></Flex>
        </Card>
      </Box>
    </>
  );
}
export { Profile };
