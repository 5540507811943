import { useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';
import { PagedResult } from 'common/models';

const fetchOrders = async (page?: number, pageSize?: number, orderStates?: string[], clientId?: string) => {
    return (await axios.get<PagedResult<Order>>(
        `orders/`, // TODO : fetch this value
        {
            params: {
                state__in: orderStates.join(","),
                page_size: pageSize,
                page: page,
                client: clientId,
            }
        })).data;
};

interface IUseOrdersProps {
    customQueryKeyPrefix?: string;
    page?: number;
    pageSize?: number;
    orderStates?: string[];
    clientId?: string;
    onSuccess?: (data: PagedResult<Order>) => void
}

/**
 * used to get user order
 * @returns
 */
const useOrders = (props: IUseOrdersProps) => {
    const { customQueryKeyPrefix, page, pageSize, orderStates, clientId, onSuccess } = props;
    const {
        data: orders,
        isLoading: isLoadingOrders,
        isError: isErrorOrders,
        error: loadingOrdersError,
        refetch: refetchOrders
    } = useQuery<PagedResult<Order>, AxiosError<any>>([customQueryKeyPrefix ?? 'orders', page, pageSize, orderStates, clientId], () => fetchOrders(page, pageSize, orderStates, clientId), {
        keepPreviousData: true, onSuccess: onSuccess
    });
    return { orders, isLoadingOrders, isErrorOrders, loadingOrdersError, refetchOrders };
};

export default useOrders;