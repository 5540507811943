import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { FormikErrors } from "formik";
import { Component } from "react";

interface IFormikYupInputFieldProps {
  InputElement?: typeof Input | typeof Textarea;
  label: string;
  type?: React.HTMLInputTypeAttribute;
  formikField: string;
  formikValue: any;
  yupValidationErrors?: string;
  formikHandleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  formikSetFieldTouched: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean
  ) => Promise<void> | Promise<FormikErrors<any>>;
  helperText?: string;
}
const FormikYupInputField = (props: IFormikYupInputFieldProps) => {
  const {
    InputElement,
    label,
    type,
    formikField,
    formikValue,
    yupValidationErrors,
    formikHandleChange,
    formikSetFieldTouched,

    helperText,
  } = props;
  var Element = InputElement ?? Input;
  return (
    <FormControl isInvalid={yupValidationErrors != undefined}>
      <FormLabel>{label}</FormLabel>
      <Element
        type={type}
        name={formikField}
        onChange={(e) => {
          formikHandleChange(e);
          formikSetFieldTouched(formikField);
        }}
        value={formikValue}
      />
      {!yupValidationErrors ? (
        <FormHelperText>{helperText}</FormHelperText>
      ) : (
        <FormErrorMessage>{yupValidationErrors}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default FormikYupInputField;
