import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  Text,
  Icon,
  IconButton,
} from "@chakra-ui/react";
import { HiExternalLink } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

interface TroubleShootingGuideProps {
  troubleShootingGuide: TroubleshootingGuide;
}
const TroubleShootingGuideTab = (props: TroubleShootingGuideProps) => {
  const { troubleShootingGuide } = props;
  const navigate = useNavigate();
  if (!troubleShootingGuide || !troubleShootingGuide.id) {
    return (
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <div>
          There Are No Trouble Shooting Guide Uploaded For This Device By Admin
        </div>
      </Box>
    );
  }
  return (
    <TableContainer>
      <Table
        size="md"
        variant="striped"
        colorScheme={"yellow"}
        style={{ borderCollapse: "initial" }}
      >
        <Thead>
          <Tr style={{ backgroundColor: "gold" }}>
            <Th>
              <Text as="b">Description</Text>
            </Th>
            <Th>
              <Text as="b">Problem Cause</Text>
            </Th>
            <Th>
              <Text as="b">Solution</Text>
            </Th>
            <Th>
              <Text as="b">Expand</Text>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {troubleShootingGuide.problems.map((p) => (
            <Tr key={p.id}>
              <Td>{p.description}</Td>
              <Td>{p.problem_cause}</Td>
              <Td>{p.problem_solution}</Td>

              <Td>
                <IconButton
                  aria-label="Search database"
                  style={{ backgroundColor: "#00000020" }}
                  onClick={async (e) => {
                    e.stopPropagation();
                    navigate(`../problems/${p.id}`)
                  }}
                  icon={<Icon as={HiExternalLink} />}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th colSpan={4}></Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

export default TroubleShootingGuideTab;
