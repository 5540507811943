import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  Select,
  useColorModeValue,
  Textarea,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import pdf from "assets/img/files-icons/pdf.png";
import { ExternalLinkIcon, DeleteIcon } from "@chakra-ui/icons";
import { Image, Grid, GridItem, Stack } from "@chakra-ui/react";
import * as Yup from "yup";
import Card from "components/card/Card";
import { useState, useContext } from "react";
import { AuthContext } from "contexts/AuthContext";
import { useFormik } from "formik";
import { toPascalCase } from "utilities/utilities";
import { Upload } from "../../../components/upload/upload";
import TextFiled from "components/textFiled/TextFiled";
import useDevices from "hooks/data-hooks/useDevices";
import useOrderContacts from "hooks/data-hooks/useOrderContacts";
import useAuth from "hooks/useAuth";
import useOrderSubmitter from "hooks/data-hooks/useOrderSubmitter";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ImageModal from "components/image-modal";
import FormikYupInputField from "components/formik-yup-input-field/formik-yup-input-field";
const orderTypes = ["Service Order", "Inspection Order", "Service Content Emergency Order"];
const orderWorkStatuses = ["Normal Workday Hours", "Weekend Hours", "Urgent Request"];
const serviceTypes = [
  "Service Contract",
  "Inspection Service",
  "OnSite Service With Parts",
  "OnSite Service Without Parts",
  "Online Service",
];
const serviceReportType = ["PDF", "Hard Copy", "E-mail"];
const deviceStatuses = ["RUNNING", "STOPPED"];

function CreateOrder() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState(undefined);
  // Chakra Color Mode
  const { file, action } = useContext(AuthContext);
  const [isSchecduleVisitDate, setIsSchecduleVisitDate] = useState<boolean>(false);
  const [serviceType, setServiceType] = useState<string>("withContacts");
  const [createServiceDetailInput, setCreateServiceDetailInput] = useState<CreateServiceDetailInput>();
  const [currTroubleshootingGuide, setCurrTroubleshootingGuide] = useState<TroubleshootingGuide>(null);
  const [orderProblemImagesCreateInput, setOrderProblemImagesCreateInput] = useState<OrderProblemImagesCreateInput>({
    problem_images: [],
    comments: [],
  });
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColor = useColorModeValue("navy.700", "white");
  const navigate = useNavigate();
  const bg = useColorModeValue("white", "navy.700");
  const auth = useAuth();
  const { devices, isLoadingDevices, isErrorLoadingDevices } = useDevices({
    actor: "client",
    userId: auth.getUser().pk,
  });
  const { orderContacts, isLoadingOrderContacts, isErrorOrderContacts } = useOrderContacts();
  const validationSchema = Yup.object().shape({
    // title: Yup.string().required(),
    // description: Yup.string().required(),
    // order_number: Yup.string().required(),
    device: Yup.string().required(),
    // order_contact: Yup.string().required(),
    // technical: Yup.string().required(),
  });
  /*
    problem_images: any[];
    scheduled_visit_date? :string;
    service_details:string;
    device_status?:"RUNNING"|"STOPPED";
    frequency_of_occurrence?:number;
    date_occurrence?:string;
  */
  const formik = useFormik<CreateOrderInput>({
    initialValues: {
      description: "",
      client: auth.getUser().pk,
      device: "",
      order_work_status: "Normal Workday Hours",
      order_type: "Service Order",
      order_contact: "",
      device_status: "RUNNING",
      problem_images: [],
      scheduled_visit_date: null,
      date_occurrence: new Date().toISOString(),
      service_details: "",
      pdf_file: null,
      comment: "",
      frequency_of_occurrence: 0,
    },
    onSubmit: async (values) => {
      create();
    },
    validationSchema: validationSchema,
  });
  const { create, isCreatingOrder, isErrorCreatingOrder } = useOrderSubmitter({
    createServiceDetailInput,
    orderProblemImagesCreateInput,
    createOrderInput: formik.values,
    tsgId: currTroubleshootingGuide?.id,
    problemSelected: currTroubleshootingGuide?.problem_selected,
    onError: () => {
      toast.error("Failed To Create Order");
    },
    onSuccess: () => {
      toast.success("Order created Successfuly");
      navigate("../overview");
    },
  });
  if (isErrorLoadingDevices || isErrorOrderContacts) {
    // TODO : fix this
    return <>Error Here!</>;
  }

  if (isLoadingDevices || isLoadingOrderContacts) {
    // TODO : fix this
    return <>Loading..</>;
  }
  const deleteImage = async (index?: number) => {
    if (window.confirm("Do You Want To Delete This Image")) {
      setOrderProblemImagesCreateInput({
        problem_images: orderProblemImagesCreateInput.problem_images.filter((img, i) => i != index),
        comments: orderProblemImagesCreateInput.comments.filter((img, i) => i != index),
      });
    }
  };
  return (
    <>
      <Box pt={{ base: "50px", md: "0px" }} mt={{ xl: "70px" }}>
        <Card mb={{ base: "0px", "2xl": "20px" }}>
          <Text color={textColor} fontSize="2xl" fontWeight="700">
            Order Information :
          </Text>
          <FormControl>
            <Flex direction={{ sm: "column", xl: "row" }} gap="15px">
              {/* Order Type */}
              <FormControl>
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Order Type<Text color={brandStars}>*</Text>
                </FormLabel>
                <Select
                  value={formik.values.order_type}
                  name={"order_type"}
                  onChange={formik.handleChange}
                  placeholder={"Select Your Order Type"}
                  mb="24px"
                >
                  {orderTypes.map((y, i) => (
                    <option key={i} value={y}>
                      {y}
                    </option>
                  ))}
                </Select>
                {/*<Select
                  defaultValue={{ id: "", value: "" }}
                  onChange={(val) => formik.setFieldValue("order_type", val.id)}
                  options={[
                    {
                      id: "Service Order",
                      value: "Service Order",
                    },
                    {
                      id: "Inspection Order",
                      value: "Inspection Order",
                    },
                    {
                      id: "Service Content Emergency Order",
                      value: "Service Content Emergency Order",
                    },
                  ]}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  mb="24px"
                  fontWeight="500"
                  error={formik.errors.device ? true : false}
                />*/}
              </FormControl>{" "}
              {/* Order Work Status */}
              <FormControl>
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Order Work Status<Text color={brandStars}>*</Text>
                </FormLabel>
                <Select
                  value={formik.values.order_work_status}
                  name={"order_work_status"}
                  onChange={formik.handleChange}
                  placeholder={"Select Your Order Work Status"}
                >
                  {orderWorkStatuses.map((y, i) => (
                    <option key={i} value={y}>
                      {y}
                    </option>
                  ))}
                </Select>
              </FormControl>{" "}
            </Flex>{" "}
            <Flex direction={{ sm: "column", xl: "row" }} gap="15px">
              {/* Order Contact */}
              <FormControl>
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Order Contact <Text color={brandStars}>*</Text>
                </FormLabel>
                <Select
                  value={formik.values.order_contact}
                  name={"order_contact"}
                  onChange={formik.handleChange}
                  placeholder={"Select Your Order Contact"}
                  mb="24px"
                >
                  {orderContacts.results.map((y, i) => (
                    <option key={i} value={y.id}>
                      {y.contact_name}
                    </option>
                  ))}
                </Select>
              </FormControl>{" "}
            </Flex>{" "}
            <Flex direction={{ sm: "column", xl: "row" }} gap="15px">
              {/* Device */}
              <FormControl>
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Device <Text color={brandStars}>*</Text>
                </FormLabel>
                <Select
                  mb={"24px"}
                  value={formik.values.device}
                  name={"devcie"}
                  onChange={(e) => {
                    formik.setFieldValue("device", e.target.value);
                    setCurrTroubleshootingGuide({
                      ...devices?.results?.filter((d) => d.id == e.target.value)?.at(0)?.troubleshooting_guid,
                    });
                  }}
                  placeholder={"Select Your Device"}
                >
                  {devices.results.map((y, i) => (
                    <option key={i} value={y.id}>
                      {y.device_name}
                    </option>
                  ))}
                </Select>
              </FormControl>{" "}
              {/* Troubleshooting Guide */}
              <FormControl>
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Troubleshooting Guide <Text color={brandStars}>*</Text>
                </FormLabel>
                <Select
                  disabled={currTroubleshootingGuide == null || currTroubleshootingGuide?.problems?.length == 0}
                  value={currTroubleshootingGuide?.problem_selected}
                  name={"devcie"}
                  onChange={(e) =>
                    setCurrTroubleshootingGuide({
                      ...currTroubleshootingGuide,
                      problem_selected: e.target.value,
                    })
                  }
                  placeholder={"Select Your Problem From Troubleshooting Guide"}
                >
                  {currTroubleshootingGuide?.problems?.map((y, i) => (
                    <option key={i} value={y.id}>
                      {y.description}
                    </option>
                  ))}
                </Select>
              </FormControl>{" "}
              {/*TODO : Review This */}
              {/* Trouble Message */}
              <TextFiled
                name={"description"}
                value={formik.values.description ?? "Descripe Your Problem Please..."}
                onChange={formik.handleChange}
                label={"Trouble Message"}
                isDisabled={false}
                textColor={textColor}
                brandStars={brandStars}
                isRequired={true}
              />
            </Flex>
            <Flex direction={{ sm: "column", xl: "row" }} gap="15px">
              {/* Date Occurrence */}
              <FormControl>
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Date Occurrence
                </FormLabel>
                <Input
                  mb="24px"
                  type={"date"}
                  required={true}
                  isRequired={true}
                  pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z"
                  value={formik.values.date_occurrence?.slice(0, 10) ?? new Date().toISOString().slice(0, 10)}
                  max={new Date().toISOString().slice(0, 10)}
                  onChange={(e) => {
                    if (!e.target.value) {
                      return;
                    }
                    const [y, m, d] = e.target.value.split("-");
                    const iso8601Date = new Date(Number(y), Number(m) - 1, Number(d) + 1).toISOString();
                    formik.setFieldValue("date_occurrence", iso8601Date);
                  }}
                />
              </FormControl>
              {/* Device Status */}
              <FormControl>
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Device Status<Text color={brandStars}>*</Text>
                </FormLabel>
                <Select
                  value={formik.values.device_status}
                  name={"device_status"}
                  onChange={formik.handleChange}
                  placeholder={"Select Your Device Status"}
                >
                  {deviceStatuses.map((y, i) => (
                    <option key={i} value={y}>
                      {toPascalCase(y)}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {/*TODO : Review This */}
              {/* Frequency of occurence */}
              <TextFiled
                name={"frequency_of_occurrence"}
                value={formik.values.frequency_of_occurrence.toString()}
                onChange={formik.handleChange}
                label={"Frequency of occurence"}
                isDisabled={false}
                textColor={textColor}
                brandStars={brandStars}
                isRequired={true}
              />
            </Flex>
            <Flex direction={{ sm: "column", xl: "row" }} gap="15px">
              <FormControl>
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Do You Want To Scheduled Visit Date?
                </FormLabel>
                <Select
                  value={isSchecduleVisitDate.toString()}
                  onChange={(e) => setIsSchecduleVisitDate(e.target.value === "true")}
                  mb="24px"
                >
                  <option key={0} value={"true"}>
                    Yes, Create One
                  </option>
                  <option key={1} value={"false"}>
                    No, Thanks
                  </option>
                </Select>
              </FormControl>
              {isSchecduleVisitDate && (
                <FormControl>
                  <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                    Scheduled Visit Date
                  </FormLabel>
                  <Input
                    type={"date"}
                    required={true}
                    isRequired={true}
                    pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z"
                    value={formik.values.scheduled_visit_date?.slice(0, 10) ?? new Date().toISOString().slice(0, 10)}
                    min={new Date().toISOString().slice(0, 10)}
                    onChange={(e) => {
                      if (!e.target.value) {
                        return;
                      }
                      const [y, m, d] = e.target.value.split("-");
                      const iso8601Date = new Date(Number(y), Number(m) - 1, Number(d) + 1).toISOString();
                      formik.setFieldValue("scheduled_visit_date", iso8601Date);
                    }}
                  />
                </FormControl>
              )}
            </Flex>
            <Flex direction={{ sm: "column", xl: "row" }} gap="15px">
              <FormControl>
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  What Type Of Service You Want?
                </FormLabel>
                <Select mb="24px" value={serviceType} onChange={(e) => setServiceType(e.target.value)}>
                  <option key={0} value={"withContacts"}>
                    With Service Contracts
                  </option>
                  <option key={1} value={"withoutContracts"}>
                    Without Service Contracts
                  </option>
                </Select>
              </FormControl>
              {serviceType == "withContacts" && (
                <FormControl key="w-f1">
                  <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                    Service Contract No
                  </FormLabel>
                  <Input
                    placeholder="Enter Youe Service Contact No."
                    required={false}
                    value={createServiceDetailInput?.service_contract_id}
                    onChange={(e) => {
                      setCreateServiceDetailInput({
                        ...createServiceDetailInput,
                        service_contract_id: e.target.value,
                      });
                    }}
                  />
                </FormControl>
              )}
              {serviceType == "withoutContracts" && (
                <FormControl>
                  <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                    Service Type
                  </FormLabel>
                  <Select
                    value={createServiceDetailInput?.service_type}
                    onChange={(e) => {
                      setCreateServiceDetailInput({
                        ...createServiceDetailInput,
                        // @ts-ignore
                        service_type: e.target.value,
                      });
                    }}
                    placeholder={"Select Your Service Type"}
                  >
                    {serviceTypes.map((y, i) => (
                      <option key={i} value={y}>
                        {y}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Flex>
            <Flex direction={{ sm: "column", xl: "row" }} gap="15px">
              <FormControl>
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Service Report Type
                </FormLabel>
                <Select
                  mb="24px"
                  value={createServiceDetailInput?.service_report_type}
                  onChange={(e) => {
                    setCreateServiceDetailInput({
                      ...createServiceDetailInput,
                      // @ts-ignore
                      service_report_type: e.target.value,
                    });
                  }}
                  placeholder={"Select Your Service Report Type"}
                >
                  {serviceReportType.map((y, i) => (
                    <option key={i} value={y}>
                      {y}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Flex>
            <Flex direction={{ sm: "column", xl: "row" }} gap="15px" my={"20px"}>
              <FormControl>
                <FormikYupInputField
                  InputElement={Textarea}
                  label="Comment : "
                  type="text"
                  formikField="actions"
                  formikValue={formik.values.comment}
                  yupValidationErrors={null}
                  formikHandleChange={formik.handleChange}
                  formikSetFieldTouched={formik.setFieldTouched}
                  // helperText="Enter Comment About Your Device (Optional)."
                />
              </FormControl>
            </Flex>
            {/* Images And Comments */}
            <Box>
              <Card mb={{ base: "0px", "2xl": "20px" }}>
                <Text color={textColor} fontSize="2xl" ms="24px" fontWeight="700">
                  Your Issue information :
                </Text>
                <FormControl>
                  <Card
                    bg={bg}
                    display={"flex"}
                    justifyItems={"center"}
                    justifyContent={"center"}
                    alignItems="flex-start"
                    gap={"15px"}
                  >
                    <Text color={textColor} fontSize="34px" textAlign="start" fontWeight="700" lineHeight="100%">
                      Issue Images
                    </Text>
                    <Grid
                      templateColumns={{
                        base: "repeat(1, 1fr)",
                        md: "repeat(2, 1fr)",
                        lg: "repeat(3, 1fr)",
                        xl: "repeat(3, 1fr)",
                      }}
                      mx="auto"
                      gap={{ base: 5, md: 8 }}
                      p={4}
                    >
                      {orderProblemImagesCreateInput.problem_images?.map((e, index) => {
                        const imageUrl = URL.createObjectURL(e);
                        return (
                          <>
                            <GridItem key={index}>
                              <Flex direction="column" align="center">
                                <Image
                                  borderRadius={"10px"}
                                  style={{ aspectRatio: "4/3" }}
                                  width={{
                                    base: "200px",
                                    md: "400px",
                                    xl: "500px",
                                  }}
                                  src={imageUrl}
                                  objectFit="cover"
                                  onClick={() => deleteImage(index)}
                                />
                                <Text as="b" mt={2}>
                                  {e.name}
                                </Text>
                                <Stack spacing={2} direction={"row"}>
                                  <IconButton
                                    aria-label="delete"
                                    colorScheme="red"
                                    icon={<DeleteIcon />}
                                    onClick={() => deleteImage(index)}
                                  />
                                  <IconButton
                                    aria-label="expand"
                                    colorScheme="blue"
                                    icon={<ExternalLinkIcon />}
                                    onClick={() => {
                                      onOpen();
                                      setSelectedImage({ name: e.name, url: imageUrl });
                                    }}
                                  />
                                </Stack>
                                <FormControl>
                                  <Textarea
                                    value={orderProblemImagesCreateInput.comments[index]}
                                    onChange={(e) => {
                                      let commentsList = [...orderProblemImagesCreateInput.comments];
                                      commentsList[index] = e.target.value;
                                      setOrderProblemImagesCreateInput({
                                        ...orderProblemImagesCreateInput,
                                        comments: commentsList,
                                      });
                                    }}
                                    isRequired={true}
                                    variant="auth"
                                    fontSize="sm"
                                    style={{
                                      border: "1px solid",
                                      borderColor: "var(--chakra-colors-secondaryGray-100)",
                                    }}
                                    ms={{ base: "0px", md: "0px" }}
                                    mb="24px"
                                    mt="5px"
                                    fontWeight="500"
                                    size="md"
                                    placeholder="Write Your Comment... (Optional)"
                                  />
                                </FormControl>
                              </Flex>
                            </GridItem>
                          </>
                        );
                      })}
                    </Grid>
                    <Upload
                      //@ts-ignore
                      onUpload={(file: File) => {
                        setOrderProblemImagesCreateInput({
                          comments: [...orderProblemImagesCreateInput.comments, ""],
                          problem_images: [...orderProblemImagesCreateInput.problem_images, file],
                        });
                      }}
                      maxW={"500px"}
                      justifyItems={"center"}
                      justifyContent={"center"}
                      gridArea={{
                        base: "3 / 1 / 4 / 2",
                        lg: "1 / 3 / 2 / 4",
                      }}
                      minH={{ base: "auto", lg: "420px", "2xl": "365px" }}
                      pe="20px"
                      pb={{ base: "100px", lg: "20px" }}
                    />
                  </Card>
                </FormControl>
                <Flex direction={{ base: "row", md: "row" }} justifyContent={"space-between"}></Flex>
              </Card>
            </Box>
            {/* Pdf File */}
            <Card
              bg={bg}
              display={"flex"}
              justifyItems={"center"}
              justifyContent={"center"}
              alignItems="flex-start"
              gap={"15px"}
            >
              <Text color={textColor} fontSize="34px" textAlign="start" fontWeight="700" lineHeight="100%">
                Pdf File
              </Text>
              {formik.values.pdf_file != null ? (
                <Stack alignItems={"center"}>
                  <Image src={pdf} alt="" width={"100px"} />
                  <Text as="b" mt={2}>
                    {formik.values.pdf_file.name}
                  </Text>
                  <IconButton
                    aria-label="delete"
                    colorScheme="red"
                    icon={<DeleteIcon />}
                    onClick={() => {
                      if (window.confirm("Do You Want To Delete This File")) {
                        formik.setFieldValue("pdf_file", null);
                        //setPdfFile(null);
                      }
                    }}
                  />
                </Stack>
              ) : (
                <Upload
                  onClick={() => {
                    formik.setFieldTouched("pdf_file");
                    formik.setFieldTouched("comment");
                  }}
                  title="Upload File PDF"
                  subTitle="Only PDF Files Are Allowed"
                  //@ts-ignore
                  onUpload={(file: File) => {
                    formik.setFieldValue("pdf_file", file);
                    //setPdfFile(file);
                    /*formik.setFieldValue("device_images", [
                    ...formik.values.device_images,
                    file,
                  ]);*/
                  }}
                  maxW={"500px"}
                  justifyItems={"center"}
                  justifyContent={"center"}
                  gridArea={{
                    base: "3 / 1 / 4 / 2",
                    lg: "1 / 3 / 2 / 4",
                  }}
                  minH={{ base: "auto", lg: "420px", "2xl": "365px" }}
                  pe="20px"
                  pb={{ base: "100px", lg: "20px" }}
                />
              )}
            </Card>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              disabled={isCreatingOrder}
              onClick={() => formik.handleSubmit()}
            >
              {isCreatingOrder ? "Creating..." : "Create Order"}
            </Button>
          </FormControl>
          <Flex direction={{ base: "row", md: "row" }} justifyContent={"space-between"}></Flex>
        </Card>
      </Box>
      {selectedImage && (
        <ImageModal
          isOpen={isOpen}
          onClose={() => {
            setSelectedImage(null);
            onClose();
          }}
          title={selectedImage?.name}
          url={selectedImage?.url}
        />
      )}
    </>
  );
}
export { CreateOrder };
