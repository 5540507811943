import { Box, Button, Flex, FormControl, FormLabel, Input, Select, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card";
import { choicesQuestionOptions } from "consts/const";
import useSurvey from "hooks/data-hooks/useSurvey";
import useSurveySubmitter from "hooks/data-hooks/useSurveySubmitter";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export interface ISurveyProps {}

export default function Survey(props: ISurveyProps) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { orderId, surveyId } = state ?? ({} as { orderId?: string; surveyId?: string });
  const { survey, isLoadingSurvey, isErrorSurvey, loadingSurveyError } = useSurvey({
    orderId: orderId,
    surveyId: surveyId,
  });
  const { createSurveyAnswers, isCreatingSurveyAnswers, isErrorCreatingSurveyAnswers } = useSurveySubmitter({
    onSuccess: () => {
      navigate("../");
    },
  });
  const [userAnswers, setUserAnswers] = React.useState<CreateSurveyAnswer[]>([]);
  const textColor = useColorModeValue("navy.700", "white");
  const handleQuestionAnswerChanged = (questionId: string, newAnswer?: string) => {
    console.log("Question Answer Changed : " + questionId + " ," + newAnswer);
    console.log("User Answers : " + JSON.stringify(userAnswers));

    if (newAnswer == null) {
      // Remove Question Answer
      console.log("Remove");
      setUserAnswers(userAnswers.filter((q) => q.question !== questionId));
      return;
    }

    if (userAnswers.findIndex((q) => q.question === questionId) !== -1) {
      // Update Question Answer
      console.log("Update Answer");
      setUserAnswers(
        userAnswers.map((q) => {
          if (q.question !== questionId) {
            return q;
          }
          return {
            ...q,
            answer: newAnswer,
          };
        })
      );
      return;
    }

    // Add Question Answer
    console.log("Add Answer");
    setUserAnswers([...userAnswers, { answer: newAnswer, question: questionId, order: orderId } as CreateSurveyAnswer]);
  };

  const getSurveyQuestionAnswerValue = (surveyQuestion: SurveyQuestion, type: "choices" | "text") => {
    if (surveyQuestion.answer != null) {
      return surveyQuestion.answer.answer;
    }
    const questionId = surveyQuestion.id;
    const answerIndex = userAnswers.findIndex((q) => q.question === questionId);
    if (answerIndex == -1) {
      if (type == "choices") {
        return "None";
      } else if (type == "text") {
        return "";
      }
    }
    return userAnswers.at(answerIndex)?.answer ?? "";
  };

  if (orderId == null || surveyId == null) {
    return <>Not Allowed!</>;
  }

  if (isErrorSurvey) {
    return <>Error While Loading Survey!</>;
  }

  if (isLoadingSurvey) {
    return <>Loading!</>;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card mb={{ base: "0px", "2xl": "20px" }}>
        <Text color={textColor} fontSize="2xl" fontWeight="700">
          Order Survey :
        </Text>
        {survey.results.length === 0 ? (
          <Text>There Are No Uploaded Survey Question For This Order</Text>
        ) : (
          <FormControl>
            {survey.results.map((surveyQuestion, index) => {
              if (surveyQuestion.type === "choices") {
                return (
                  <Flex direction={{ sm: "column", xl: "row" }} gap="15px" my="20px">
                    <FormControl>
                      <FormLabel display="flex" ms="4px" fontSize="lg" fontWeight="600" color={textColor} mb="8px">
                        {surveyQuestion.question}
                      </FormLabel>
                      <Select
                        disabled={surveyQuestion.answer != null}
                        value={getSurveyQuestionAnswerValue(surveyQuestion, "choices")}
                        onChange={(e: any) =>
                          handleQuestionAnswerChanged(
                            surveyQuestion.id,
                            e.target.value === "None" ? undefined : e.target.value
                          )
                        }
                        mb="24px"
                      >
                        <>
                          <option value="None">Select Option ---</option>
                          {choicesQuestionOptions.map((c, i) => (
                            <option key={i} value={c}>
                              {c}
                            </option>
                          ))}
                        </>
                      </Select>
                    </FormControl>
                  </Flex>
                );
              } else if (surveyQuestion.type === "text") {
                return (
                  <Flex direction={{ sm: "column", xl: "row" }} gap="15px" my="20px">
                    <FormControl>
                      <FormLabel display="flex" ms="4px" fontSize="lg" fontWeight="600" color={textColor} mb="8px">
                        {surveyQuestion.question}
                      </FormLabel>
                      <Input
                        placeholder="Enter Your Answer."
                        disabled={surveyQuestion.answer != null}
                        required={false}
                        value={getSurveyQuestionAnswerValue(surveyQuestion, "text")}
                        onChange={(e: any) => {
                          handleQuestionAnswerChanged(
                            surveyQuestion.id,
                            e.target.value === "" ? undefined : e.target.value
                          );
                        }}
                      />
                    </FormControl>
                  </Flex>
                );
              }
              return <>Unknown Type</>;
            })}
            <Button
              fontSize="md"
              variant="brand"
              fontWeight="500"
              w="25%"
              h="50"
              mb="24px"
              disabled={userAnswers.length === 0 || isCreatingSurveyAnswers}
              onClick={() => createSurveyAnswers(userAnswers)}
            >
              {isCreatingSurveyAnswers ? "Submitting Survey..." : "Submit Survey"}
            </Button>
          </FormControl>
        )}
      </Card>
    </Box>
  );
}
