import {
  Flex,
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import * as React from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

// Custom components
import Card from "components/card/Card";
import { Link } from "react-router-dom";
import Pagination from "components/Pagination/Pagination";
import { PaginationState } from "common/models";
import useTechnicians from "hooks/data-hooks/useTechnicians";
import useOrderUpdater from "hooks/data-hooks/useOrderUpdater";
import { toast } from "react-toastify";

type PendingOrderRow = {
  id: string;
  title: string;
  state: string;
  description: string;
  client: string;
  device: string;
  order_number: string;
  order_contact: string[];
  technical: string[];
};
// const TechCard = (id: number, name: string) => {
//   return <Flex backgroundColor={}></Flex>;
// };
const columnHelper = createColumnHelper<PendingOrderRow>();

interface IAdminPendingOrdersTableProps {
  tableData: PendingOrderRow[];
  pagination: PaginationState;
  setPagination: (newState: PaginationState) => void;
  onOrderUpdated: () => void;
  isLoading: boolean;
  hasPagination?: boolean;
}

export default function AdminPendingOrdersTable(props: IAdminPendingOrdersTableProps) {
  // deconstruct props
  const { hasPagination = true, tableData, pagination, setPagination, onOrderUpdated, isLoading } = props;

  const [data, setData] = React.useState(() => [...tableData]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedTechnical, setSelectedTechnical] = React.useState(undefined);

  // Load Technicians using useTechnicians custom hook
  const { technicians, isLoadingTechnicians, isErrorTechnicians } = useTechnicians({ page: null, pageSize: null });

  // Use our custom hook to update the order
  const { update, isUpdatingOrder, isErrorUpdatingOrder } = useOrderUpdater({
    onSuccess: () => {
      toast.success("Report Created Successfully!");
      setSelectedOrderId(undefined);
    },
    onError: () => {
      toast.error("Failed To Submit The Report!");
      setSelectedOrderId(undefined);
    },
  });

  const [selectedOrderId, setSelectedOrderId] = React.useState(undefined);

  // Define Style Props
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const acceptButton = useColorModeValue("green.500", "whiteAlpha.100");
  const rejectButton = useColorModeValue("red.500", "whiteAlpha.100");

  const rejectOrder = async (id: string) => {
    update({
      id,
      state: "REJECTED",
    });
    if (onOrderUpdated) {
      onOrderUpdated();
    }
  };
  const assignOrder = async () => {
    update({
      id: selectedOrderId,
      state: "ACCEPTED",
      technical: [selectedTechnical],
    });
    if (onOrderUpdated) {
      onOrderUpdated();
    }
    setModalOpen(false);
  };

  // Define Table Columns
  const columns = [
    columnHelper.accessor("title", {
      id: "title",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Title
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("description", {
      id: "description",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          description
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("client", {
      id: "client",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Client
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("device", {
      id: "device",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Device
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("state", {
      id: "state",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          State
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("technical", {
      id: "technical",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Technical
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Actions
        </Text>
      ),
      cell: (info) => (
        <Flex color={textColor} fontSize="sm" fontWeight="700">
          <Flex direction={{ xl: "row", sm: "column" }} gap={"15px"}>
            <Button
              bg={acceptButton}
              _hover={{ bg: "green.300" }}
              _active={{ bg: "whiteAlpha.100" }}
              mb={{ sm: "16px", xl: "24px" }}
              color={"white"}
              fontWeight="regular"
              fontSize="sm"
              minW="185px"
              mx="auto"
              disabled={selectedOrderId === info.getValue()}
              onClick={() => {
                setSelectedOrderId(info.getValue());
                setModalOpen(true);
              }}
            >
              Accept and assign to technical
            </Button>
            <Button
              bg={rejectButton}
              _hover={{ bg: "red.300" }}
              _active={{ bg: "whiteAlpha.100" }}
              mb={{ sm: "16px", xl: "24px" }}
              color={"white"}
              fontWeight="regular"
              fontSize="sm"
              mx="auto"
              disabled={selectedOrderId === info.getValue()}
              onClick={() => {
                console.log("rejected order Id : " + info.getValue());
                setSelectedOrderId(info.getValue());
                rejectOrder(info.getValue());
              }}
            >
              reject
            </Button>
            {/* {info.getValue()} */}
            <Link to={"/admin/order/" + `${info.getValue()}`}>
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="80%"

                // borderRadius={"100px"}
              >
                Show
              </Button>
            </Link>
          </Flex>
        </Flex>
      ),
    }),
  ];

  // define the table using useReactTable
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Modal isCentered onClose={() => {}} isOpen={modalOpen} motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Technical</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              setModalOpen(false);
            }}
          />
          <ModalBody>
            <Card display={"flex"} gap={"5px"}>
              {isLoadingTechnicians ? (
                <>Loading....</>
              ) : isErrorTechnicians ? (
                <>Error...</>
              ) : (
                <FormControl>
                  <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                    Technician
                  </FormLabel>
                  <Select
                    value={selectedTechnical ?? "None"}
                    onChange={(e) => {
                      setSelectedTechnical(e.target.value);
                    }}
                  >
                    <option disabled>Select Technician---</option>
                    {technicians?.results?.map((t, i) => (
                      <option key={i} value={t.technical}>
                        {t.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                setSelectedOrderId(undefined);
                setModalOpen(false);
              }}
            >
              Close
            </Button>
            <Button
              variant="ghost"
              disabled={!selectedTechnical}
              onClick={() => {
                assignOrder();
              }}
            >
              Apply
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text color={textColor} fontSize="22px" mb="4px" fontWeight="700" lineHeight="100%">
          New Orders
        </Text>
        <Link to={"../initialized-orders"}>
          <Button>View All Initialized Orders</Button>
        </Link>
      </Flex>
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {props.tableData?.length == 0 ? (
              <Tr>
                <Td colSpan={7} style={{ textAlign: "center", color: "black", fontSize: "1.2rem", padding: "4rem" }}>
                  You Have Not Any New Orders Yet!
                </Td>
              </Tr>
            ) : (
              table.getRowModel().rows.map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
        {hasPagination && (
          <Pagination
            currentPage={pagination.page}
            itemsPerPage={pagination.perPage}
            paginate={(val) => {
              setPagination({
                ...pagination,
                page: val.page,
                perPage: val.perPage,
              });
            }}
            totalItems={pagination.count}
            disabled={isLoading}
          />
        )}
      </Box>
    </Card>
  );
}
