import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import OrderAttachmentsTab from "./order-modal.tabs/order-attachments-tab";
import OrderImagesTab from "./order-modal.tabs/order-images-tab";
import OrderInfoTab from "./order-modal.tabs/order-info-tab";
interface OrderModalProps {
  isOpen: boolean;
  onClose: () => void;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "full";
  order?: Order;
}
const OrderModal = (props: OrderModalProps) => {
  if (!props.order) {
    return null;
  }
  const order = props.order;
  return (
    // TODO : add images
    <Modal autoFocus={false} size={props.size ?? "6xl"} isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent>
        <ModalHeader>
          {order.title != "" ? order.title : "Untitled"} ({order.order_type}) - No. {order.order_number}{" "}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs isLazy isFitted variant="enclosed">
            <TabList mb="1em">
              <Tab>Order Info</Tab>
              <Tab>Problem Images</Tab>
              <Tab>Attachments</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <OrderInfoTab order={order} />
              </TabPanel>
              <TabPanel>
                <OrderImagesTab images={order.problem_images} />
              </TabPanel>
              <TabPanel>
                <OrderAttachmentsTab pdfFile={order?.pdf_file} comment={order?.comment} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={props.onClose}>
            Close
          </Button>
          {/* <Button variant="ghost">Secondary Action</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrderModal;
