import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/App.css";
import {
  HashRouter,
  Route,
  Routes,
  Navigate,
  BrowserRouter,
  Link,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
// import Store from "views/Store";
import PrivateRoute from "components/routing-components/private-route";
import { MissingRoute } from "components/routing-components/missing-route";
import Customers from "views/admin/customers/customers";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "providers/AuthProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { store } from "store";
import { App } from "App";
import axios from "axios";
// init QueryClient object from react-query
const queryClient = new QueryClient();

// change default baseUrl for axios client
axios.defaults.baseURL = process.env.REACT_APP_BACK_END_API_LINK;

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <App/>
            <ToastContainer />
          </AuthProvider>
        </QueryClientProvider>
      </Provider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
