import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
// Assets ;
import { useState, useEffect } from "react";
import { orderStateOptions } from "consts/const";
import OrdersTable from "../../../components/data-tables/orders-table";
import useOrders from "hooks/data-hooks/useOrders";
import { TableCardSkelton } from "components/skeltons/table-card-skelton";
export default function Orders() {
  const [ordersPageState, setOrdersPageState] = useState({
    page: 1,
    perPage: 10,
    count: 0,
  });
  const [selectedOrderStates, setSelectedOrderStates] = useState(orderStateOptions);
  const [selectedClient, setSelectedClient] = useState<ClientLite>(undefined);
  const { orders, isLoadingOrders, isErrorOrders, loadingOrdersError } = useOrders({
    page: ordersPageState.page,
    pageSize: ordersPageState.perPage,
    orderStates: selectedOrderStates,
    clientId: selectedClient?.id,
    onSuccess: (data) => setOrdersPageState({ ...ordersPageState, count: data.count }),
  });

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex align="center" gap={"10px"} direction={{ base: "column", md: "column" }}>
        {isLoadingOrders ? (
          <TableCardSkelton />
        ) : isErrorOrders ? (
          <TableCardSkelton
            message={loadingOrdersError?.response?.data?.message ?? "Un Expected Error Happen While Loading Orders!"}
            isErrorSkelton={true}
          />
        ) : (
          <OrdersTable
            selectedOrderStates={selectedOrderStates}
            setSelectedOrderStates={setSelectedOrderStates}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
            tableData={
              orders?.results?.map((r) => ({
                id: r.id,
                title: r.title,
                state: r.state,
                description: r.description,
                client: r.client, //.name,
                device: r.device.name, //.name,
                order_number: r.order_number,
                fullObject: r,
              })) || []
            }
            pagination={ordersPageState}
            setPagination={setOrdersPageState}
            isLoading={isLoadingOrders}
          />
        )}
      </Flex>
    </Box>
  );
}
