import { Box, Flex } from "@chakra-ui/react";
import { PaginationState } from "common/models";
import DevicesTable from "components/data-tables/devices-table";
import { TableCardSkelton } from "components/skeltons/table-card-skelton";
import useDevices from "hooks/data-hooks/useDevices";
import useAuth from "hooks/useAuth";
import { useState } from "react";

const Devices = () => {
  const auth = useAuth();

  // device pagination state
  const [pageState, setPageState] = useState<PaginationState>({
    page: 1,
    perPage: 10,
    count: 0,
  });

  // detching date using useDevice custom hook
  const {
    devices,
    isLoadingDevices,
    isErrorLoadingDevices,
    loadingDevicesError,
  } = useDevices({
    actor: "technical",
    page: pageState.page,
    pageSize: pageState.perPage,
    onLoadDevicesSuccess: (data) => setPageState({ ...pageState, count: data.count }),
  });

  return isLoadingDevices ? (
    <TableCardSkelton />
  ) : isErrorLoadingDevices ? (
    <TableCardSkelton
      message={
        loadingDevicesError?.response?.data?.message ??
        "Un Expected Error Happen While Loading Devices!"
      }
      isErrorSkelton={true}
    />
  ) : (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex
        align="center"
        gap={"10px"}
        direction={{ base: "column", md: "column" }}
      >
        <DevicesTable
          tableData={
            devices?.results?.map((r) => ({
              image: r.device_images?.at(0)?.image,
              name: r.device_name,
              type: r.device_type,
              model: r.device_model,
              number_of_orders: r.order?.length ?? 0,
              tsgId: r.troubleshooting_guid?.id,
              fullObject: r,
            })) || []
          }
          pagination={pageState}
          setPagination={setPageState}
          isLoading={isLoadingDevices}
        />
      </Flex>
    </Box>
  );
};

export default Devices;
