import { Box, Grid, GridItem, Image, IconButton, Icon } from "@chakra-ui/react";
import { MdFileDownload } from "react-icons/md";
import pdf from "assets/img/files-icons/pdf.png";
interface InstallationGuideProps {
  installationGuide?: InstallationGuide;
}
const InstallationGuideTab = (props: InstallationGuideProps) => {
  const { installationGuide } = props;
  if (!installationGuide || !installationGuide.id) {
    return (
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <div>
          There Are No Installation Guide Uploaded For This Device By Admin
        </div>
      </Box>
    );
  }
  return (
    <>
      <Grid templateColumns="repeat(8, 1fr)" gap={6}>
        {installationGuide.installation_files.map((i) => (
          <GridItem w="100%" h="100%" key={i.id}>
            <Box className="iscontainer" style={{ aspectRatio: "10/8" }}>
              {/* TODO : add file icon mapper like  */}
              <Image className="isimage" src={pdf} alt="" />
              <Box className="ismiddle">
                <IconButton
                  aria-label="Search database"
                  style={{ backgroundColor: "#00000020" }}
                  onClick={async (e) => {
                    e.stopPropagation();
                    const fileUrl = `${process.env.REACT_APP_BACK_END_IMG_LINK}${i.file}`;
                    window.open(fileUrl, "_blank");
                  }}
                  icon={<Icon as={MdFileDownload} />}
                />
              </Box>
              <Box>
                {installationGuide.installation_files[0].file.split("/").pop()}
              </Box>
            </Box>
          </GridItem>
        ))}
      </Grid>
    </>
  );
};

export default InstallationGuideTab;
