import { Flex, Box, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Button } from "@chakra-ui/react";
import * as React from "react";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { Link } from "react-router-dom";
import { PaginationState } from "common/models";
import Pagination from "components/Pagination/Pagination";

type TechnicianRow = {
  identity_image: string;
  domain: string;
  phone_number: string;
  address: string;
};

const columnHelper = createColumnHelper<TechnicianRow>();

interface ITechniciansTableProps {
  tableData: TechnicianRow[];
  pagination: PaginationState;
  setPagination: (newState: PaginationState) => void;
  isLoading: boolean;
}

export default function TechniciansTable(props: ITechniciansTableProps) {
  const { tableData, pagination, setPagination, isLoading } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  let defaultData = tableData;
  const columns = [
    columnHelper.accessor("domain", {
      id: "domain",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Domain
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Link style={{ color: "orange" }} to={""}>
            {info.getValue() ?? "None"}
          </Link>
        </Flex>
      ),
    }),
    columnHelper.accessor("phone_number", {
      id: "phone_number",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Phone number
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue() ?? "None"}
        </Text>
      ),
    }),
    columnHelper.accessor("address", {
      id: "address",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          address
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue() ?? "None"}
        </Text>
      ),
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text color={textColor} fontSize="22px" mb="4px" fontWeight="700" lineHeight="100%">
          Technicians Table
        </Text>
      </Flex>
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {props.tableData?.length == 0 ? (
              <Tr>
                <Td colSpan={7} style={{ textAlign: "center", color: "black", fontSize: "1.2rem", padding: "4rem" }}>
                  You Have Not Any New Orders Yet!
                </Td>
              </Tr>
            ) : (
              table.getRowModel().rows.map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
        <Pagination
          currentPage={pagination.page}
          itemsPerPage={pagination.perPage}
          paginate={(val) => {
            setPagination({
              ...props.pagination,
              page: val.page,
              perPage: val.perPage,
            });
          }}
          totalItems={pagination.count}
          disabled={isLoading}
        />
      </Box>
    </Card>
  );
}
