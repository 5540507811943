import { Box } from "@chakra-ui/react";
import ImageGrid from "components/image-grid/image-grid";
interface DeviceImagesTabProps {
  images?: DeviceImage[];
}
const DeviceImagesTab = (props: DeviceImagesTabProps) => {
  const { images } = props;
  if (!images || images.length == 0) {
    return (
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <div>There Are No Images Uploaded For This Device By Customer</div>
      </Box>
    );
  }
  const gridImages = images.map((i) => {
    return { id: i.id, url: i.image } as GridImage;
  });
  return <ImageGrid images={gridImages} />;
};

export default DeviceImagesTab;
