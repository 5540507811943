/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import useAuth from "hooks/useAuth";
import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isLoggedIn } from "utilities/utilities";

const PrivateRoute = ({ rule }: { rule: string }) => {
  // TODO : use AuthProvider to detect rule and isLoggedIn
  const auth = useAuth();
  const isAuthintecated = auth.isLoggedIn && auth.getRule() == rule;
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const setupInterceptors = async () => {
      await auth.intercept();
      setIsLoading(false);
    };

    setupInterceptors();
    return () => auth.unIntercept();
  }, []);

  if (isLoading) {
    return <>Loading....</>;
  }
  
  if (!isAuthintecated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  // TODO : check this stmt in tiktag
  return (
    <>
      <Outlet />
    </>
  );
};

export default PrivateRoute;
