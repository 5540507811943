import React from "react";
import axios, { Axios, AxiosResponse } from "axios";
import { LOCAL_STORAGE_KEYS, USER_RULE_TYPE } from "consts/const";
import jwtDecode from "jwt-decode";

type AuthContextType = {
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  accessToken: string;
  signin: (
    email: string,
    password: string,
    callback: (data?: LoginResponse, error?: ErrorResponse) => void
  ) => Promise<void>;
  signout: (callback: () => void) => Promise<void>;
  refreshTokens: (callback: (data?: RefreshTokensResponse, error?: ErrorResponse) => void) => Promise<void>;
  verifyAccessToken: (callback: (isValid: boolean, error?: ErrorResponse) => void) => Promise<void>;
  isThereStoredSession: () => boolean;
  intercept: () => void;
  unIntercept: () => void;
  setUser: (user: string | null) => void; // TODO : remove this
  getRule: () => USER_RULE_TYPE;
  getExpiresIn: () => string;
  getUser: () => User;
};
const AuthContext = React.createContext<AuthContextType>(null);

const AuthProvider = ({ children }: ChildrenNodes) => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [isLoggingIn, setIsLoggingIn] = React.useState(false);
  const [accessToken, setAccessToken] = React.useState<string>(null);
  const [requestInterceptor, setRequestInterceptor] = React.useState(-1);
  const [responseInterceptor, setResponseInterceptor] = React.useState(-1);
  const signin = async (
    email: string,
    password: string,
    callback: (data?: LoginResponse, error?: ErrorResponse) => null
  ) => {
    setIsLoggingIn(true);
    const loginRequest = {
      email: email,
      password: password,
    };
    const url = `${process.env.REACT_APP_BACK_END_API_LINK}user/login/`;
    axios
      .post<LoginResponse>(url, loginRequest)
      .then((response) => response.data)
      .then((data) => {
        setIsLoggedIn(true);
        localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, data.access_token);
        localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, data.refresh_token);
        localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ID, data.user.pk);
        localStorage.setItem(LOCAL_STORAGE_KEYS.EXPIRES_IN, (jwtDecode(data.access_token) as any).exp);
        localStorage.setItem(LOCAL_STORAGE_KEYS.USER_TYPE, data.user.role);
        localStorage.setItem(LOCAL_STORAGE_KEYS.USER_NAME, data.user.username);
        setAccessToken(data.access_token);
        callback(data, null);
      })
      .catch((error) => {
        console.error("Login Failed. The Error Is : ", error);
        callback(null, {
          message: error.response?.data?.non_field_errors[0] ?? "Un expected error happen",
          httpCode: error.response?.status,
        });
      })
      .finally(() => {
        setIsLoggingIn(false);
      });
  };
  const refreshTokens = async (callback: (data?: RefreshTokensResponse, error?: ErrorResponse) => null) => {
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    if (refreshToken == null) {
      // TODO : remove interceptors
      callback(null, { message: "Refresh Token Not Exist" });
      return;
    }
    const refreshTokensRequest = {
      refresh: refreshToken,
    };
    // TODO : fix this url
    const url = `${process.env.REACT_APP_BACK_END_API_LINK}user/token-refresh/`;
    axios
      .post<RefreshTokensResponse>(url, refreshTokensRequest)
      .then((response) => response.data)
      .then((data) => {
        setIsLoggedIn(true);
        localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, data.access);
        /*localStorage.setItem(
          LOCAL_STORAGE_KEYS.REFRESH_TOKEN,
          data.refresh_token
        );
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.EXPIRES_IN,
          (jwtDecode(data.access_token) as any).exp
        );*/
        setAccessToken(data.access);
        callback(data, null);
      })
      .catch((error) => {
        if (error?.response?.data != null) {
          callback(null, {
            message: error.response.data,
            httpCode: error.response.status,
          });
        } else {
          setIsLoggedIn(false);
          //localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
          //localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
          axios.interceptors.request.clear();
          axios.interceptors.response.clear();
          callback(null, { message: "Un expected error happen" });
        }
      });
  };
  const signout = async (callback: () => void) => {
    // TODO : Add Signout logic
    setIsLoggedIn(false);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_ID);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.EXPIRES_IN);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_TYPE);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_NAME);
    axios.interceptors.request.clear();
    axios.interceptors.response.clear();
    callback();
  };
  const verifyAccessToken = async (callback: (isValid: boolean, error?: ErrorResponse) => void) => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    if (accessToken == null) {
      // TODO : remove interceptors
      callback(false, { message: "Access Token Not Exist" });
      return;
    }
    const verifyAccessTokenRequest = {
      token: accessToken,
    };
    // TODO : fix this url
    const url = `${process.env.REACT_APP_BACK_END_API_LINK}user/token-verify/`;
    axios
      .post<RefreshTokensResponse>(url, verifyAccessTokenRequest)
      .then((response) => response.data)
      .then((data) => {
        setIsLoggedIn(true);
        callback(true, null);
      })
      .catch((error) => {
        if (error?.response?.data != null) {
          callback(false, {
            message: error.response.data,
            httpCode: error.response.status,
          });
        } else {
          setIsLoggedIn(false);
          localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
          localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
          axios.interceptors.request.clear();
          axios.interceptors.response.clear();
          callback(false, { message: "Un expected error happen" });
        }
      });
  };
  const isThereStoredSession = () => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    return accessToken != null && refreshToken != null;
  };
  const intercept = () => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        const token = "Bearer " + getToken();
        if (token) {
          config.headers["Authorization"] = token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    setRequestInterceptor(requestInterceptor);
    const responseInterceptor = axios.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      async (err: any) => {
        const originalConfig = err.config;
        if (err.response) {
          //console.log("Reponse Error : ",originalConfig);
          // Access Token was expired
          if (err.response.status === 401 && !originalConfig._retry) {
            // to avoid infinite loop set _retry = true
            originalConfig._retry = true;
            try {
              // TODO : refresh token
              //await this._authenticationService.refreshAccessToken();
              /*const tenantId = this._authenticationService.getTenantConfig()?.activationKey;
                          if (accessToken) {
                              const token = this._authenticationService.getAccessTokenType() + " " + this._authenticationService.getAccessToken()
                              originalConfig.headers["Authorization"] = token
                              originalConfig.headers["__tenant"] = tenantId
                              originalConfig.headers["x-lily-userId"] = userId
                              //originalConfig.headers["x-access-token"] = accessToken;
                          } */
              return axios(originalConfig);
            } catch (_error) {
              if (_error.response && _error.response.data) {
                return Promise.reject(_error.response.data);
              }

              return Promise.reject(_error);
            }
          }

          if (err.response.status === 403 && err.response.data) {
            return Promise.reject(err.response.data);
          }
        }

        return Promise.reject(err);
      }
    );
    setResponseInterceptor(responseInterceptor);
  };

  const unIntercept = () => {
    axios.interceptors.request.eject(requestInterceptor);
    axios.interceptors.response.eject(responseInterceptor);
  };

  // const setToken = (access_token: string | null) => {
  //   if (access_token == null) {
  //     localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  //     return;
  //   }
  //   localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, access_token);
  // };

  const setUser = (user: string | null) => {
    if (user == null) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_ID);
      return;
    }
    localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ID, user);
  };
  // const setRule = (rule: USER_RULE_TYPE | null) => {
  //   if (rule == null) {
  //     localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_TYPE);
  //     return;
  //   }
  //   localStorage.setItem(LOCAL_STORAGE_KEYS.USER_TYPE, rule);
  // };
  const getRule = (): USER_RULE_TYPE => {
    return (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_TYPE) as USER_RULE_TYPE) || undefined;
  };
  // const setExpiresIn = (expires_in: string | null) => {
  //   if (expires_in == null) {
  //     localStorage.removeItem(LOCAL_STORAGE_KEYS.EXPIRES_IN);
  //     return;
  //   }
  //   localStorage.setItem(LOCAL_STORAGE_KEYS.EXPIRES_IN, expires_in);
  // };

  const getExpiresIn = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.EXPIRES_IN);
  };
  const getUser = () => {
    return {
      pk: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
      role: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_TYPE),
      username: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_NAME),
    } as User;
  };

  const getToken = (): string => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "";
  };
  const value: AuthContextType = {
    isLoggedIn,
    isLoggingIn,
    accessToken,
    signin,
    signout,
    refreshTokens,
    verifyAccessToken,
    isThereStoredSession,
    intercept,
    unIntercept,
    setUser,
    getRule,
    getExpiresIn,
    getUser,
  };
  return <AuthContext.Provider value={value}> {children} </AuthContext.Provider>;
};

export { AuthProvider, AuthContext };
