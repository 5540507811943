import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tabs,
  TabList,
  Tab,
  Text,
  TabPanels,
  TabPanel,
  Divider,
  HStack,
  Select,
  FormLabel,
  Flex,
  FormControl,
  Input,
} from "@chakra-ui/react";
import useReportSubmitter from "hooks/data-hooks/useReportSubmitter";
import useReportTemplates from "hooks/data-hooks/useReportTemplates";
import { useState } from "react";
import { toast } from "react-toastify";
import { toPascalCase } from "utilities/utilities";
import ReportDetailsTab from "./report-modal.tabs/report-details-tab";
import ReportInfoTab from "./report-modal.tabs/report-info-tab";
import { TemplateFactory } from "./template-factory/template-factory";
interface ReportModalProps {
  isOpen: boolean;
  onClose: () => void;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "full";
  orderId?: string;
  serviceDetails?: ServiceDetail;
}

const ReportModal = (props: ReportModalProps) => {
  const [costDetails, setCostDetails] = useState({});
  const { create, isCreatingReport, isErrorCreatingReport } =
    useReportSubmitter({
      serviceDetails: props.serviceDetails,
      costDetails: costDetails,
      onSuccess: () => {
        toast.success("Report Created Successfully!");
      },
      onError: () => {
        toast.error("Failed To Submit The Report!");
      },
    });
  const [createReportInput, setCreateReportInput] =
    useState<CreateServiceReportInput>({
      service_template_name: undefined,
      details: undefined,
      next_visit_plan: new Date().toISOString(),
      report_status: "INITIAL",
    });

  if (!props.orderId) {
    return null;
  }

  return (
    // TODO : add images
    <Modal
      autoFocus={false}
      size={props.size ?? "6xl"}
      isOpen={props.isOpen}
      onClose={props.onClose}
      isCentered
      scrollBehavior={"inside"}
    >
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent minH={"80%"}>
        <ModalHeader>Create Order Report</ModalHeader>
        <ModalCloseButton />
        <Divider orientation="horizontal" />
        <ModalBody>
          <Tabs isLazy isFitted variant="enclosed">
            <TabList mb="1em">
              <Tab>Report Info</Tab>
              <Tab>Details</Tab>
            </TabList>
            <TabPanels>
              {/* Info */}
              <TabPanel>
                <ReportInfoTab
                  createReportInput={createReportInput}
                  onCreateReportInputChange={setCreateReportInput}
                  costDetails={costDetails}
                  onCostDetailsChanged={setCostDetails}
                />
              </TabPanel>
              {/* Details */}
              <TabPanel>
                <ReportDetailsTab
                  createReportInput={createReportInput}
                  onCreateReportInputChange={setCreateReportInput}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blackAlpha"
            mr={3}
            disabled={isCreatingReport}
            onClick={props.onClose}
          >
            Close
          </Button>
          <Button
            colorScheme="blue"
            mr={3}
            disabled={isCreatingReport}
            onClick={() => {
              create(createReportInput);
            }}
          >
            {isCreatingReport ? "Submitting..." : "Submit"}
          </Button>
          {/* <Button variant="ghost">Secondary Action</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReportModal;
