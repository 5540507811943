import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Image
} from "@chakra-ui/react";
import * as React from "react";

export interface IImageModalProps {
  title: string;
  url: string;
  onClose: () => void;
  isOpen: boolean;
}

export default function ImageModal(props: IImageModalProps) {
  const { title, url, onClose, isOpen } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Image
            borderRadius={"10px"}
            style={{ aspectRatio: "4/3" }}
            width={{ base: "200px", md: "400px", xl: "500px" }}
            src={url}
            objectFit="cover"
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
