import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { Box, Flex, Input, Spacer, IconButton } from "@chakra-ui/react";
import * as React from "react";

export interface IKeyValueInputProps {
  data: { [key: string]: string };
  onChange: (newData: { [key: string]: string }) => void;
  keyPlaceholder?: string;
  valuePlaceholder?: string;
}

export default function KeyValueInput(props: IKeyValueInputProps) {
  const { data, onChange, keyPlaceholder, valuePlaceholder } = props;
  const [newKey, setNewKey] = React.useState("");
  const [newValue, setNewValue] = React.useState("");

  const handleAddKeyValue = () => {
    if (newKey && newValue) {
      onChange({ ...data, [newKey]: newValue });
      setNewKey("");
      setNewValue("");
    }
  };

  const handleRemoveKeyValue = (key: string) => {
    const updatedData = { ...data };
    delete updatedData[key];
    onChange(updatedData);
  };

  return (
    <div>
      <Box mb={4}>
        <Flex gap={"10px"}>
          <Input
            type="text"
            placeholder={keyPlaceholder ?? "Key"}
            value={newKey}
            onChange={(e) => setNewKey(e.target.value)}
          />
          <Spacer />
          <Input
            type="text"
            placeholder={valuePlaceholder ?? "Value"}
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
          />
          <Spacer />
          <IconButton
            aria-label="Add"
            icon={<AddIcon />}
            onClick={handleAddKeyValue}
          />
        </Flex>
      </Box>
      <Box>
        {Object.entries(data).map(([key, value]) => (
          <Flex key={key} mb={2}>
            <Box>
              <strong>{key}:</strong> {value}
            </Box>
            <Spacer />
            <IconButton
              aria-label="Remove"
              icon={<DeleteIcon />}
              onClick={() => handleRemoveKeyValue(key)}
            />
          </Flex>
        ))}
      </Box>
    </div>
  );
}
