import { Box, Center, Flex, useColorModeValue } from "@chakra-ui/react";
import { PagedResult, PaginationState } from "common/models";
import DevicesTable from "components/data-tables/devices-table";
import OrdersTable from "components/data-tables/orders-table";
import { TableCardSkelton } from "components/skeltons/table-card-skelton";
import { orderStateOptions } from "consts/const";
import useAuth from "hooks/useAuth";
import useDevices from "hooks/data-hooks/useDevices";
import useOrders from "hooks/data-hooks/useOrders";
import { useState } from "react";
export default function CutomerHomePage() {
  const auth = useAuth();

  // Selected Orders State (Used To Filter Orders Depending On Thier State)
  const [selectedOrderStates, setSelectedOrderStates] = useState<string[]>(orderStateOptions);

  // Tables Pagination States
  const [ordersPageState, setOrdersPageState] = useState<PaginationState>({
    page: 1,
    perPage: 10,
    count: 0,
  });
  const [devicesPageState, setDevicesPageState] = useState<PaginationState>({
    page: 1,
    perPage: 10,
    count: 0,
  });

  // data fetching hooks
  const { devices, isLoadingDevices, isErrorLoadingDevices, loadingDevicesError } = useDevices({
    actor: "client",
    userId: auth.getUser().pk,
    page: devicesPageState.page,
    pageSize: devicesPageState.perPage,
    onLoadDevicesSuccess: (data) => setDevicesPageState({ ...devicesPageState, count: data.count }),
  });

  const { orders, isLoadingOrders, isErrorOrders, loadingOrdersError } = useOrders({
    page: ordersPageState.page,
    pageSize: ordersPageState.perPage,
    orderStates: selectedOrderStates,
    clientId: undefined,
    onSuccess: (data) => setOrdersPageState({ ...ordersPageState, count: data.count }),
  });

  const isErrorBoth = isErrorLoadingDevices && isErrorOrders;
  const isLoadingBoth = isLoadingDevices && isLoadingOrders;
  if (isLoadingBoth) {
    return <TableCardSkelton />;
  }
  if (isErrorBoth) {
    return <>Some Error Happen!</>;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex align="center" gap={"10px"} direction={{ base: "column", md: "column" }}>
        <>
          {isLoadingOrders ? (
            <TableCardSkelton />
          ) : isErrorOrders ? (
            <TableCardSkelton
              message={loadingOrdersError?.response?.data?.message ?? "Un Expected Error Happen While Loading Orders!"}
              isErrorSkelton={true}
            />
          ) : (
            <OrdersTable
              selectedOrderStates={selectedOrderStates}
              setSelectedOrderStates={setSelectedOrderStates}
              tableData={
                orders?.results?.map((r) => ({
                  id: r.id,
                  title: r.title,
                  state: r.state,
                  description: r.description,
                  client: r.client, //.name,
                  device: r.device.name, //.name,
                  order_number: r.order_number,
                  fullObject: r,
                })) || []
              }
              pagination={ordersPageState}
              setPagination={setOrdersPageState}
              isLoading={isLoadingOrders}
            />
          )}
          {isLoadingDevices ? (
            <TableCardSkelton />
          ) : isErrorLoadingDevices ? (
            <TableCardSkelton
              message={
                loadingDevicesError?.response?.data?.message ?? "Un Expected Error Happen While Loading Devices!"
              }
              isErrorSkelton={true}
            />
          ) : (
            <DevicesTable
              tableData={
                devices?.results?.map((r) => ({
                  image: r.device_images?.at(0)?.image,
                  name: r.device_name,
                  type: r.device_type,
                  model: r.device_model,
                  number_of_orders: r.order?.length ?? 0,
                  tsgId: r.troubleshooting_guid?.id,
                  fullObject: r,
                })) || []
              }
              pagination={devicesPageState}
              setPagination={setDevicesPageState}
              isLoading={isLoadingDevices}
            />
          )}
        </>
      </Flex>
    </Box>
  );
}
