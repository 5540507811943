import { Icon } from "@chakra-ui/react";
import { MdBarChart, MdHome, MdLock } from "react-icons/md";

// Auth Imports
import Customers from "views/admin/customers/customers";
import Customer from "views/admin/customers/customer";
import Technicals from "views/admin/technicians/technicians";
import Technical from "views/admin/technicians/technician/technician";
import { ShowOrder } from "views/admin/OrderDetails";
import { Navigate } from "react-router-dom";
import HomePage from "views/admin/home-page/home-page";
import InitializedOrders from "views/admin/init-orders/init-orders";
import Machines from "views/admin/machines/machines";
import Orders from "views/admin/orders/orders";
const routes = [
  {
    name: "Home Page",
    layout: "/admin",
    path: "",
    sideBar: true,
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <Navigate to="/home" replace={true} />,
  },
  {
    name: "Home Page",
    layout: "/admin",
    path: "/home",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <HomePage />,
  },
  {
    name: "Customers",
    layout: "/admin",
    path: "/customers",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: <Customers />,
  },
  {
    name: "Technicians",
    layout: "/admin",
    path: "/technicians",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: <Technicals />,
  },
  {
    sideBar: true,
    name: "Technicians",
    layout: "/admin",
    path: "/technicians/:id",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: <Technical />,
  },
  {
    sideBar: true,
    name: "Customer",
    layout: "/admin",
    path: "/customer/:id",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: <Customer />,
  },
  {
    name: "Initialized Orders",
    layout: "/admin",
    path: "/initialized-orders",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: <InitializedOrders />,
  },
  {
    name: "Machines",
    layout: "/admin",
    path: "/machines",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: <Machines />,
  },
  {
    name: "Orders",
    layout: "/admin",
    path: "/orders",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: <Orders />,
  },
  {
    sideBar: true,
    name: "Customer",
    layout: "/admin",
    path: "/order/:id",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: <ShowOrder />,
  },
];

export default routes;
