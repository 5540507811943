import axios from "axios";
import { useQuery } from "react-query";
const fetchDeviceProblem = async (problemId: string) => {
    const response = await axios.get<Problem>( `devices/problems/${problemId}`)
    return response.data;
}
const useDeviceProblem = (problemId: string) => {
    const { isLoading, isError, error, data: deviceProblem } = useQuery('problem' + problemId, () => fetchDeviceProblem(problemId));
    return { isLoading, isError, error, deviceProblem }
};

export default useDeviceProblem;