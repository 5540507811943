import axios from "axios";
import { useQuery } from "react-query";

const fetchTemplates = async () => {
    return (await axios.get<any>('/services/service-template-names')).data.template_names as string[];
}
const useReportTemplates = (enabled:boolean = true) => {
    const { data: templates, isLoading: isLoadingTemplates, isError: isErrorLoadingTemplates } = useQuery(["report-templates"], fetchTemplates, {
        keepPreviousData: true,
        enabled:enabled
    });
    return { templates, isLoadingTemplates, isErrorLoadingTemplates };
}
export default useReportTemplates;