import { useQuery } from "react-query";
import axios, { AxiosError } from "axios";
import { PagedResult } from "common/models";

const fetchSurvey = async (
    orderId: string,
    surveyId: string,
    page?: number,
    pageSize?: number
) => {
    return (
        await axios.get<PagedResult<SurveyQuestion>>(
            `surveys/${surveyId}/questions/`,
            {
                params: {
                    page_size: pageSize,
                    page: page,
                    order_id: orderId
                },
            }
        )
    ).data;
};

/**
 *
 */
interface IUseSurveyProps {
    page?: number;
    pageSize?: number;
    orderId?: string;
    surveyId?: string;
    onLoadSurveySuccess?: (data: PagedResult<SurveyQuestion>) => void;
}

/**
 * used to get order survey
 * @returns
 */
const useSurvey = (props: IUseSurveyProps) => {
    // deconstruction props
    const {
        page,
        pageSize,
        orderId,
        surveyId,
        onLoadSurveySuccess,
    } = props;

    // data fetching useQuery hook (used to fetch order survey)
    const {
        data: survey,
        isLoading: isLoadingSurvey,
        isError: isErrorSurvey,
        error: loadingSurveyError,
    } = useQuery<PagedResult<SurveyQuestion>, AxiosError<any>>(
        ["survey", page, pageSize, orderId, surveyId],
        () => fetchSurvey(orderId, surveyId, page, pageSize),
        {
            keepPreviousData: true,
            enabled: orderId != null && surveyId != null,
            onSuccess: onLoadSurveySuccess,
        }
    );

    // return hooks result
    return {
        survey,
        isLoadingSurvey,
        isErrorSurvey,
        loadingSurveyError,
    };
};

export default useSurvey;