import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Grid, GridItem, Box, IconButton, Image, Text } from "@chakra-ui/react";
import "assets/css/ImageTab.css";
interface ImageGridProps {
  images: GridImage[];
}
const ImageGrid = (props: ImageGridProps) => {
  const { images } = props;
  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={6}>
      {images.map((i) => {
        const url = i.url.startsWith("http")
          ? i.url
          : `${process.env.REACT_APP_BACK_END_IMG_LINK}${i.url}`;
        return (
          <GridItem w="100%" h="100%" key={i.id}>
            <Box className="iscontainer">
              <Image
                className="isimage"
                src={url}
                alt={"unable to load the image" ?? "unknown"}
              />

              <Box className="ismiddle">
                <IconButton
                  aria-label="Download Image"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(url, "_blank").focus();
                  }}
                  icon={<ExternalLinkIcon />}
                />
              </Box>
            </Box>
            {i.comment && i.comment != "" && (
              <Text as="b" display="block" w="100%" align="center">
                {i.comment}
              </Text>
            )}
          </GridItem>
        );
      })}
    </Grid>
  );
};

export default ImageGrid;
