import PrivateRoute from "components/routing-components/private-route";
import AuthLayout from "./layouts/auth";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import Layout from "layouts/default-layout";
import technicalRoutes from "./routing/technical-routes";
import clientRoutes from "./routing/client-routes";
import adminRoutes from "./routing/admin-routes";
import { MissingRoute } from "components/routing-components/missing-route";
import SignInCentered from "./views/auth/signIn";
import MainDashboard from "views/customer/home-page/home-page";
import { getRoutes } from "utilities/tsx-utilities";
export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignInCentered />} />
        <Route path="/login" element={<SignInCentered />} />
        <Route element={<PrivateRoute rule={"ADMIN"} />}>
           <Route
            path={`/admin`}
            element={<Layout routes={adminRoutes} />}
          >
            {getRoutes(adminRoutes, "/admin")}
          </Route> 
        </Route>
        <Route element={<PrivateRoute rule={"TECHNICAL"} />}>
          <Route
            path={`/technical`}
            element={<Layout routes={technicalRoutes} />}
          >
            {getRoutes(technicalRoutes, "/technical")}
          </Route>
        </Route>
        <Route element={<PrivateRoute rule={"CLIENT"} />}>
          <Route
            path={`/client`}
            element={<Layout routes={clientRoutes} />}
          >
            {getRoutes(clientRoutes, "/client")}
          </Route>
        </Route>
        {/* <Route path={`/addEvent`} element={<AddEvent />} /> */}
        {/* <Route path={`/store`} element={<Store />} /> */}
        {/* TODO : navigate to 404 rather than / */}
        <Route path="*" element={<MissingRoute />} />
      </Routes>
    </BrowserRouter>
  );
};
