import { Box, Divider, Text } from "@chakra-ui/react";
import useAuth from "hooks/useAuth";
import * as React from "react";
import { useLocation } from "react-router-dom";
import DeviceAttachmentsTab from "./modals/device-modal/device-model.tabs/device-attachments-tab";
import DeviceCheckListTab from "./modals/device-modal/device-model.tabs/device-checklist-tab";
import DeviceImagesTab from "./modals/device-modal/device-model.tabs/device-images-tab";
import DeviceInfoTab from "./modals/device-modal/device-model.tabs/device-info-tab";
import InstallationGuideTab from "./modals/device-modal/device-model.tabs/installation-guide-tab";
import TroubleShootingGuideTab from "./modals/device-modal/device-model.tabs/trouble-shooting-guide-tab";

export interface IPrintDevicePageProps {}

export default function PrintDevicePage(props: IPrintDevicePageProps) {
  const { state } = useLocation();
  const auth = useAuth();
  const shouldShowDeviceChecklistText = auth.getRule() == "TECHNICAL" || auth.getRule() == "ADMIN";
  React.useEffect(() => {
    if (state != null) {
      window.print();
    }
  }, []);
  if (state == null) {
    return <>Device Is Null</>;
  }
  const device = state as Device;
  return (
    <Box>
      {/* Device Info */}
      <Text fontSize={"1.5rem"} as="b">
        Device Info
      </Text>
      <DeviceInfoTab device={device} />
      <Divider sx={{ marginBlock: "20px", borderWidth: "3px", borderColor: "black" }} />
      {/*Images*/}
      <Text fontSize={"1.5rem"} as="b">
        Images
      </Text>
      <DeviceImagesTab images={device?.device_images} />
      <Divider sx={{ marginBlock: "20px", borderWidth: "2px", borderColor: "black" }} />
      {/*Trouble Shooting Guide*/}
      <Text fontSize={"1.5rem"} as="b">
        Trouble Shooting Guide
      </Text>
      <TroubleShootingGuideTab troubleShootingGuide={device?.troubleshooting_guid} />
      <Divider sx={{ marginBlock: "20px", borderWidth: "2px", borderColor: "black" }} />
      {/*Installation Guide*/}
      <Text fontSize={"1.5rem"} as="b">
        Installation Guide:
      </Text>
      <InstallationGuideTab installationGuide={device?.group?.installation_guide} />
      <Divider sx={{ marginBlock: "20px", borderWidth: "2px", borderColor: "black" }} />
      {/* Attachments */}
      {/* <Text fontSize={"1.5rem"} as="b">
        Attachments
      </Text>
      <DeviceAttachmentsTab pdfFile={device?.pdf_file} comment={device.comment} /> */}
      {/* Checklist */}
      {shouldShowDeviceChecklistText && (
        <>
          <Divider sx={{ marginBlock: "20px", borderWidth: "2px", borderColor: "black" }} />
          <Text fontSize={"1.5rem"} as="b">
            Check List
          </Text>
          <DeviceCheckListTab deviceChecklist={device?.device_checklist} />
        </>
      )}
    </Box>
  );
}
