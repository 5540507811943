import { FormControl, FormLabel, Input, Text } from "@chakra-ui/react";

const TextFiled = (props: {
  name?: string;
  value: string;
  onChange: React.ChangeEventHandler;
  label: string;
  isDisabled: boolean;
  textColor: "navy.700" | "white";
  brandStars: "brand.500" | "brand.400";
  isRequired: boolean;
}) => {
  return (
    <FormControl>
      <FormLabel
        display="flex"
        ms="4px"
        fontSize="sm"
        fontWeight="500"
        color={props.textColor}
        mb="8px"
      >
        {props.label}
        <Text color={props.brandStars}>*</Text>
      </FormLabel>
      <Input
        name={props.name}
        disabled={props.isDisabled}
        value={props.value}
        onChange={props.onChange}
        isRequired={props.isRequired}
        // variant="auth"
        // fontSize="sm"
        // ms={{ base: "0px", md: "0px" }}
        // mb="24px"
        // fontWeight="500"
        // size="lg"
      />
    </FormControl>
  );
};

export default TextFiled;
