import { Icon } from "@chakra-ui/react";
import { MdHome } from "react-icons/md";
import { Profile } from "views/technical/profile/profile";
import Orders from "views/technical/orders/orders";
import Devices from "views/technical/devices/devices";
import Problem from "views/technical/problems/problem";
import DeviceHistory from "views/technical/device-history/device-history";
import { Navigate } from "react-router-dom";
import PrintDevicePage from "components/print-device-page";
import PrintOrderPage from "components/order-to-print-page";
// TODO : change icons
const routes = [
  {
    name: "Orders",
    layout: "/technical",
    path: "",
    sideBar: true,
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <Navigate to="/home" replace={true} />,
  },
  {
    name: "Orders",
    layout: "/technical",
    path: "/home",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <Orders />,
  },
  {  
    sideBar:true,
    name: "Print Order",
    layout: "/client",
    path: "/print-order",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <PrintOrderPage />,
  },
  {  
    sideBar:true,
    name: "Print Device",
    layout: "/client",
    path: "/print-device",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <PrintDevicePage />,
  },
  {
    name: "Devices",
    layout: "/technical",
    path: "/devices",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <Devices />,
  },
  {
    sideBar: true,
    name: "Customers",
    layout: "/technical",
    path: "/problems/:problemId",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <Problem />,
  },
  {
    sideBar: true,
    name: "Device History",
    layout: "/technical",
    path: "/device-history/:tsgId",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <DeviceHistory />,
  },
  {
    sideBar: true,
    name: "Technical Profile",
    layout: "/technical",
    path: "/profile",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <Profile />,
  },
];

export default routes;
