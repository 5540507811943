
import { combineReducers } from '@reduxjs/toolkit';
import sideBarReducer from './sideBarReducer';

// reducer import

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    sideBarReducer
});
export default reducer;
