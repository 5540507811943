import { CalendarIcon, TimeIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "contexts/AuthContext";
import { json, useNavigate } from "react-router-dom";
import {
  createOrderContact,
  createOrderContactType,
} from "../../../services/order-conteact.service";
import useAuth from "hooks/useAuth";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormikYupInputField from "components/formik-yup-input-field/formik-yup-input-field";

function CreateOrderContact() {
  // Chakra Color Mode
  const { file } = useContext(AuthContext);
  const auth = useAuth();
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColor = useColorModeValue("navy.700", "white");
  const LinkColor = useColorModeValue("orange.700", "red");
  const bg = useColorModeValue("white", "navy.700");
  const validationSchema = Yup.object().shape({
    contact_name: Yup.string().required("Contact name is required"),
    location: Yup.string().required("Contact Location is required is required"),
  });
  const navigate = useNavigate();
  const formik = useFormik<createOrderContactType>({
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: {
      client: auth.getUser().pk,
      contact_name: "",
      availability_date: new Date().toISOString().slice(0, 10),
      visit_date: new Date().toISOString().slice(0, 10),
      location: "",
    },
    onSubmit: async () => {
      // console.log(typeof deviceData?.device_images);
      // console.log(deviceData?.device_images + "   sub");

      await createOrderContact(formik.values);
      navigate("../home");
    },
  });
  const isErrorContactName =
    formik.touched.contact_name && formik.errors.contact_name;
  const isErrorAvailabilityDate =
    formik.touched.availability_date && formik.errors.availability_date;
  const isErrorVisitDate =
    formik.touched.visit_date && formik.errors.visit_date;
  const isErrorLocation = formik.touched.location && formik.errors.location;
  return (
    <>
      <Box pt={{ base: "50px", md: "0px" }} mt={{ xl: "70px" }}>
        <Card mb={{ base: "0px", "2xl": "20px" }}>
          <Text color={textColor} fontSize="2xl" fontWeight="700">
            contact order information :
          </Text>
          <FormControl>
            <Flex
              direction={{ sm: "column", xl: "row" }}
              gap="15px"
              my={"20px"}
            >
              <FormikYupInputField
                label="Contact Name : "
                type="text"
                formikField="contact_name"
                formikValue={formik.values.contact_name}
                yupValidationErrors={isErrorContactName}
                formikHandleChange={formik.handleChange}
                formikSetFieldTouched={formik.setFieldTouched}
                helperText="Enter the contact name (ex. Ahmad)."
              />
              <FormControl>
                <FormLabel>Availability Date :</FormLabel>
                <Input
                  type={"date"}
                  required={true}
                  isRequired={true}
                  pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z"
                  value={
                    formik.values.availability_date?.slice(0, 10)
                  }
                  max={new Date().toISOString().slice(0, 10)}
                  onChange={(e) => {
                    if (!e.target.value) {
                      return;
                    }
                    const [y, m, d] = e.target.value.split("-");
                    const iso8601Date = new Date(
                      Number(y),
                      Number(m) - 1,
                      Number(d) + 1
                    ).toISOString();
                    formik.setFieldValue("availability_date", iso8601Date);
                  }}
                />
                {!isErrorAvailabilityDate ? (
                  <FormHelperText>
                    Enter Contact Availability Date
                  </FormHelperText>
                ) : (
                  <FormErrorMessage>{isErrorAvailabilityDate}</FormErrorMessage>
                )}
              </FormControl>
            </Flex>
            <Flex
              direction={{ sm: "column", xl: "row" }}
              gap="15px"
              my={"20px"}
            >
              <FormControl>
                <FormLabel>Visit Date :</FormLabel>
                <Input
                  type={"date"}
                  required={true}
                  isRequired={true}
                  pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z"
                  value={
                    formik.values.visit_date?.slice(0, 10)
                  }
                  max={new Date().toISOString().slice(0, 10)}
                  onChange={(e) => {
                    if (!e.target.value) {
                      return;
                    }
                    const [y, m, d] = e.target.value.split("-");
                    const iso8601Date = new Date(
                      Number(y),
                      Number(m) - 1,
                      Number(d) + 1
                    ).toISOString();
                    formik.setFieldValue("visit_date", iso8601Date);
                  }}
                />
                {!isErrorVisitDate ? (
                  <FormHelperText>Enter Contact Visit Date</FormHelperText>
                ) : (
                  <FormErrorMessage>{isErrorVisitDate}</FormErrorMessage>
                )}
              </FormControl>
              <FormikYupInputField
                label="Location : "
                type="text"
                formikField="location"
                formikValue={formik.values.location}
                yupValidationErrors={isErrorLocation}
                formikHandleChange={formik.handleChange}
                formikSetFieldTouched={formik.setFieldTouched}
                helperText="Enter the contact location (ex. Syria, Damascus)."
              />
            </Flex>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {formik.isSubmitting ? "Creating..." : "Create The Order Contact"}
            </Button>
          </FormControl>
          <Flex
            direction={{ base: "row", md: "row" }}
            justifyContent={"space-between"}
          ></Flex>
        </Card>
      </Box>
    </>
  );
}
export { CreateOrderContact };
