import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import DeviceInfoTab from "./device-model.tabs/device-info-tab";
import DeviceImagesTab from "./device-model.tabs/device-images-tab";
import TroubleShootingGuideTab from "./device-model.tabs/trouble-shooting-guide-tab";
import InstallationGuideTab from "./device-model.tabs/installation-guide-tab";
import useAuth from "hooks/useAuth";
import DeviceCheckListTab from "./device-model.tabs/device-checklist-tab";
import DeviceAttachmentsTab from "./device-model.tabs/device-attachments-tab";
interface DeviceModalProps {
  isOpen: boolean;
  onClose: () => void;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "full";
  device?: Device;
}
const DeviceModal = (props: DeviceModalProps) => {
  const auth = useAuth();
  if (!props.device) {
    return null;
  }
  const shouldShowDeviceChecklistTab = auth.getRule() == "TECHNICAL" || auth.getRule() == "ADMIN";
  const device = props.device;
  return (
    // TODO : add images
    <Modal autoFocus={false} size={props.size ?? "6xl"} isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent minHeight={"80%"}>
        <ModalHeader>
          {device.device_name != "" ? device.device_name : "Untitled"}{" "}
          {device.device_type != null && device.device_type != "" ? `(${device.device_type})` : ""}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs isLazy isFitted variant="enclosed">
            <TabList mb="1em">
              <Tab>Device Info</Tab>
              <Tab>Images</Tab>
              <Tab>Trouble Shooting Guide</Tab>
              <Tab>Installation Guide</Tab>
              <Tab>Attachments</Tab>
              {shouldShowDeviceChecklistTab && <Tab>Check List</Tab>}
            </TabList>
            <TabPanels>
              {/* Device Info */}
              <TabPanel>
                <DeviceInfoTab device={device} />
              </TabPanel>
              {/*Images*/}
              <TabPanel>
                <DeviceImagesTab images={device?.device_images} />
              </TabPanel>
              {/*Trouble Shooting Guide*/}
              <TabPanel>
                <TroubleShootingGuideTab troubleShootingGuide={device?.troubleshooting_guid} />
              </TabPanel>
              {/*Installation Guide*/}
              <TabPanel>
                <InstallationGuideTab installationGuide={device?.group?.installation_guide} />
              </TabPanel>
              {/* Attachments */}
              <TabPanel>
                <DeviceAttachmentsTab pdfFile={device?.pdf_file} comment={device.comment} />
              </TabPanel>
              {/* Checklist */}
              <TabPanel>
                <DeviceCheckListTab deviceChecklist={device?.device_checklist} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={props.onClose}>
            Close
          </Button>
          {/* <Button variant="ghost">Secondary Action</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeviceModal;
