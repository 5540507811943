import React, { useEffect, useState } from "react";
import "./Pagination.css";
import { Input, Text } from "@chakra-ui/react";

const Pagination = ({
  itemsPerPage,
  totalItems,
  paginate,
  currentPage,
  disabled,
}: {
  itemsPerPage: number;
  totalItems: number;
  paginate: (val: { page: number; perPage: number }) => void;
  currentPage: number;
  disabled: boolean;
}) => {
  const pageNumbers = [];
  const [pagination, setPagination] = useState<{
    page: number;
    perPage: number;
  }>({
    page: currentPage,
    perPage: itemsPerPage,
  });

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  useEffect(() => {
    paginate(pagination);
  }, [pagination]);

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li key={number} className="pagination-item">
            <button
              onClick={() => setPagination({ ...pagination, page: number })}
              className={`pagination-button ${pagination.page === number ? "active" : ""}`}
              style={{ paddingInline: "0.8rem", paddingBlock: "0.3rem" }}
              disabled={disabled}
            >
              {number}
            </button>
          </li>
        ))}
        <li style={{ marginLeft: "5px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <p style={{ color: "black" }}>Per page :</p>
            <Input
              w={"10"}
              h={"10"}
              type={"number"}
              style={{ color: "blue", padding: "2px", textAlign: "center" }}
              value={pagination.perPage}
              onChange={(val) =>
                setPagination({
                  ...pagination,
                  perPage: Number(val.target.value || 1),
                })
              }
              disabled={disabled}
            />
          </div>
        </li>
        <li style={{ marginInline: "1rem" }}>
          <p style={{ color: "black", display: "inline" }}>Total Count :</p> {totalItems}
        </li>
        <li style={{ marginLeft: "5px" }}>{disabled && <Text as="b"> Loading....</Text>}</li>
      </ul>
    </nav>
  );
};

export default Pagination;
