import { useMutation, useQuery } from "react-query";
import axios, { AxiosError } from "axios";
import { PagedResult } from "common/models";

const fetchTechnicians = async (
    page?: number,
    pageSize?: number
) => {
    return (
        await axios.get<PagedResult<Technician>>(
            'user/technical_profile/',
            {
                params: {
                    page_size: pageSize,
                    page: page,
                },
            }
        )
    ).data;
};

/**
 *
 */
interface IUseTechniciansProps {
    page?: number;
    pageSize?: number;
    onLoadTechniciansSuccess?: (data: PagedResult<Technician>) => void;
}

/**
 * used to get system Technicians
 * @returns
 */
const useTechnicians = (props: IUseTechniciansProps) => {
    // deconstruction props
    const {
        page,
        pageSize,
        onLoadTechniciansSuccess,
    } = props;

    // data fetching useQuery hook (used to fetch devices for Technicians table)
    const {
        data: technicians,
        isLoading: isLoadingTechnicians,
        isError: isErrorTechnicians,
        error: loadingTechniciansError,
    } = useQuery<PagedResult<Technician>, AxiosError<any>>(
        ["technicians", page, pageSize],
        () => fetchTechnicians(page, pageSize),
        {
            keepPreviousData: true,
            onSuccess: onLoadTechniciansSuccess,
        }
    );

    // return hooks result
    return {
        technicians,
        isLoadingTechnicians,
        isErrorTechnicians,
        loadingTechniciansError
    };
};

export default useTechnicians;