import { useQuery } from 'react-query';
import axios from 'axios';
import { getHeader } from 'utilities/utilities';

const fetchOrderContacts = async () => {
    return (await axios.get<OrderContactsList>(process.env.REACT_APP_BACK_END_API_LINK +
        `orders/order-contact/`, {
        headers: getHeader(),
    })).data;
};

/**
 * used to get user devices
 * @returns
 */
const useOrderContacts = () => {
    const {
        data: orderContacts,
        isLoading: isLoadingOrderContacts,
        isError: isErrorOrderContacts
    } = useQuery('order-contacts', fetchOrderContacts, { keepPreviousData: true });
    return { orderContacts, isLoadingOrderContacts, isErrorOrderContacts };
};

export default useOrderContacts;