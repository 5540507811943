import {
  Flex,
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Collapse,
  useDisclosure,
  CheckboxGroup,
  Stack,
  Checkbox,
  FormLabel,
  IconButton,
  Link,
  Tooltip,
} from "@chakra-ui/react";
import * as React from "react";
import { TbReport, TbFileInfo, TbPrinter } from "react-icons/tb";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";

// Custom components
import Card from "components/card/Card";
import Pagination from "components/Pagination/Pagination";
import { orderStateOptions } from "consts/const";
import { InfoIcon } from "@chakra-ui/icons";
import OrderModal from "../modals/order-modal/order-modal";
import { exportToExcel, toPascalCase } from "utilities/utilities";
import { PaginationState } from "common/models";
import ReportModal from "components/modals/report-modal/report-modal";
import useAuth from "hooks/useAuth";
import { blacken } from "@chakra-ui/theme-tools";

type OrderRow = {
  id: string;
  title: string;
  state: string;
  description: string;
  client: ClientLite;
  device: string;
  order_number: string;
  order_contact?: OrderContactLite[];
  technical?: TechnicalLite[];
  fullObject: Order;
};

const columnHelper = createColumnHelper<OrderRow>();
interface OrderTableProps {
  tableData: OrderRow[];
  pagination: PaginationState;
  setPagination: (newState: PaginationState) => void;
  isLoading: boolean;
  selectedOrderStates: string[];
  setSelectedOrderStates: (val: string[]) => void;
  selectedClient?: ClientLite;
  setSelectedClient?: (val: ClientLite) => void;
  viewAllOrdersRoute?: string;
  hasPagination?: boolean;
}
// const columns = columnsDataCheck;
export default function OrdersTable(props: OrderTableProps) {
  const { hasPagination = true } = props;
  const navigate = useNavigate();
  const data = props.tableData;
  const auth = useAuth();
  const { isOpen: isAdvancedFilterExpanded, onToggle: onExpandAdvancedFilter } = useDisclosure({
    defaultIsOpen: false,
  });
  const { isOpen: isOpenExpandModal, onOpen: onOpenExpandModal, onClose: onCloseExpandModal } = useDisclosure();
  const { isOpen: isOpenReportModal, onOpen: onOpenReportModal, onClose: onCloseReportModal } = useDisclosure();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [selectedOrder, setSelectedOrder] = React.useState<Order>(null);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  // let defaultData = tableData;
  const columns = [
    columnHelper.accessor("title", {
      id: "title",

      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Title
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("description", {
      id: "description",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          description
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("client", {
      id: "client",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Client
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          <Link onClick={() => props.setSelectedClient(info.getValue())}>{info.getValue().name}</Link>
        </Text>
      ),
    }),
    columnHelper.accessor("device", {
      id: "device",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Device
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("state", {
      id: "state",
      header: () => (
        <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          State
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),

    // Admin Column
    columnHelper.accessor("order_contact", {
      id: "order_contact",
      header: () =>
        auth.getRule() == "ADMIN" ? (
          <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
            Order Contact
          </Text>
        ) : null,
      cell: (info) =>
        auth.getRule() == "ADMIN" ? (
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()?.at(0)?.contact_name ?? "None"}
          </Text>
        ) : null,
    }),

    // Admin Column
    columnHelper.accessor("technical", {
      id: "technical",
      header: () =>
        auth.getRule() == "ADMIN" ? (
          <Text justifyContent="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
            Technical
          </Text>
        ) : null,
      cell: (info) =>
        auth.getRule() == "ADMIN" ? (
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()?.at(0)?.name ?? "None"}
          </Text>
        ) : null,
    }),

    columnHelper.accessor("fullObject", {
      id: "fullObject",
      header: () => (
        <Text justifyContent="end" align="end" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Actions
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {auth.getRule() == "TECHNICAL" && (
            <Tooltip label="Create Report" fontSize="md">
              <IconButton
                aria-label="Create Report"
                size="lg"
                fontSize={"3vh"}
                icon={<TbReport color="#1b2559" size={"4vh"} />}
                onClick={() => {
                  setSelectedOrder(info.getValue());
                  onOpenReportModal();
                }}
              />
            </Tooltip>
          )}
          {auth.getRule() == "CLIENT" && (
            <Tooltip label="Survey" fontSize="md">
              <IconButton
                aria-label="Survey"
                size="lg"
                fontSize={"3vh"}
                icon={<TbReport color="#1b2559" size={"4vh"} />}
                disabled={!info.getValue().survey}
                onClick={() => {
                  navigate("../survey", { state: { orderId: info.getValue().id, surveyId: info.getValue().survey } });
                }}
              />
            </Tooltip>
          )}
          <Tooltip label="Expand Order" fontSize="md">
            <IconButton
              aria-label="Expand Order"
              size="lg"
              fontSize={"3vh"}
              icon={<TbFileInfo color="#1b2559" size={"4vh"} />}
              onClick={() => {
                setSelectedOrder(info.getValue());
                onOpenExpandModal();
              }}
            />
          </Tooltip>

          <Tooltip label="Print Order" fontSize="md">
            <IconButton
              aria-label="Print Order"
              size="lg"
              fontSize={"3vh"}
              icon={<TbPrinter color="#1b2559" size={"4vh"} />}
              onClick={() => {
                navigate("../print-order", { state: info.getValue() });
              }}
            />
          </Tooltip>
        </Text>
      ),
    }),
  ];
  const isEnableResetFiltersButton = props.selectedClient != undefined;
  /*const isEnableResetFiltersButton =
    JSON.stringify(props.selectedOrderStates ?? []) != JSON.stringify(orderStateOptions) ||
    props.selectedClient != undefined;*/
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text color={textColor} fontSize="22px" mb="4px" fontWeight="700" lineHeight="100%">
          Your Orders
        </Text>
        <Flex px="25px" mb="8px" justifyContent="end" align="center">
          <Button
            onClick={() => {
              props.setSelectedOrderStates(orderStateOptions);
              props.setSelectedClient(undefined);
              onExpandAdvancedFilter();
            }}
            disabled={!isEnableResetFiltersButton}
          >
            Reset Filters
          </Button>
          <Button onClick={onExpandAdvancedFilter}>Advanced Filter</Button>
          {props.viewAllOrdersRoute && (
            <ReactRouterLink to={props.viewAllOrdersRoute}>
              <Button>View All Orders</Button>
            </ReactRouterLink>
          )}
          <Button onClick={() => exportToExcel(props.tableData)}>Export As Excel</Button>
        </Flex>
      </Flex>
      <Collapse in={isAdvancedFilterExpanded || isEnableResetFiltersButton}>
        <Box padding={"2%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <CheckboxGroup
            colorScheme="green"
            defaultValue={orderStateOptions}
            value={props.selectedOrderStates}
            onChange={(e) => {
              console.log("e" + e);
              const temp = e.map((i) => i.toString());
              props.setSelectedOrderStates([...temp]);
            }}
          >
            <FormLabel margin={"0"} marginRight={"10px"} display={"block"} as="b">
              Order State :
            </FormLabel>
            <Stack spacing={[1, 5]} direction={["column", "row"]}>
              {orderStateOptions.map((s) => (
                <Checkbox key={s} value={s}>
                  {toPascalCase(s)}
                </Checkbox>
              ))}
            </Stack>
          </CheckboxGroup>
        </Box>
        {props.selectedClient && (
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <FormLabel margin={"0"} marginRight={"10px"} as="b">
              Selected Client :
            </FormLabel>
            <Text>{props.selectedClient.name} </Text>
          </Box>
        )}
      </Collapse>
      <OrderModal order={selectedOrder} isOpen={isOpenExpandModal} onClose={onCloseExpandModal} />
      {auth.getRule() == "TECHNICAL" && (
        <ReportModal
          orderId={selectedOrder?.id}
          serviceDetails={selectedOrder?.service_details}
          isOpen={isOpenReportModal}
          onClose={onCloseReportModal}
        />
      )}
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {props.tableData?.length == 0 ? (
              <Tr>
                <Td colSpan={8} style={{ textAlign: "center", color: "black", fontSize: "1.2rem", padding: "4rem" }}>
                  You Have Not Any Orders Yet!
                </Td>
              </Tr>
            ) : (
              table.getRowModel().rows.map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
        {hasPagination && (
          <Pagination
            currentPage={props.pagination.page}
            itemsPerPage={props.pagination.perPage}
            paginate={(val) => {
              props.setPagination({
                ...props.pagination,
                page: val.page,
                perPage: val.perPage,
              });
            }}
            totalItems={props.pagination.count}
            disabled={props.isLoading}
          />
        )}
      </Box>
    </Card>
  );
}
