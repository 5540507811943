// action - state management
import { createSlice } from '@reduxjs/toolkit';
import * as actionTypes from './actions';

export const initialState: { showSidebar: boolean } = {
    showSidebar: true
};

// ==============================|| CART REDUCER ||============================== //

/*const sideBarReducer = (state = initialState, action:{type:string}) => {
    switch (action.type) {
        case actionTypes.HIDE_SIDE_BAR:
            return {
                ...state,
                cart: false
            };
        default:
            return state;
    }
};*/

const sideBarReducer = createSlice({
    name: 'sideBar',
    initialState,
    reducers: {
        hideSideBar(state, action) {
            state.showSidebar = false
        },
        showSideBar(state, action) {
            state.showSidebar = false
        }
    }
})
export const { hideSideBar, showSideBar } = sideBarReducer.actions;
export default sideBarReducer.reducer;
