import {
  Box,
  Flex,
  Text,
  Button,
  useColorModeValue,
  Stack,
  Grid,
  GridItem,
  Divider,
  Center,
  Image,
  IconButton,
  Icon,
  Link,
} from "@chakra-ui/react";
import useDeviceProblem from "hooks/data-hooks/useDeviceProblem";
import { useEffect, useState } from "react";

interface DeviceProblemWidgetProps {
  problemId: string;
}
const DeviceProblemWidget = (props: DeviceProblemWidgetProps) => {
  const { problemId } = props;
  //const [isLoading, setIsLoading] = useState(true);
  //const [problem, setProblem] = useState<Problem>(undefined);
  /*useEffect(() => {
    if (!problemId || problemId == "") {
      return;
    }
    getProblem(setProblem, setIsLoading, problemId);
  }, []);
  if (!problemId || problemId == "") {
    return <>Can't Featch Problem!</>;
  }
  if (isLoading || !problem) {
    return <>Loading...</>;
  }*/
  const { isLoading, isError, error, deviceProblem:problem } = useDeviceProblem(problemId);
  /*useEffect(() => {
    if (!extractedProblemId || extractedProblemId == "") {
      return;
    }
    getProblem(setProblem, setIsLoading, extractedProblemId);
  }, []);
  if (!extractedProblemId || extractedProblemId == "") {
    return <>Can't Featch Problem!</>;
  }*/
  if (isLoading) {
    return <>Loading...</>;
  }
  if (isError) {
    return <>Some Error Happen!</>;
  }
  return (
    <Box px="25px" maxWidth={"100%"} maxHeight={"100%"}>
      <Grid gap={4}>
        {/* Problem Discription */}
        <GridItem colSpan={4}>
          <Text as="b"> Problem Description: </Text>
          <br />
          {problem.description != "" ? problem.description : "No Discription"}
        </GridItem>
        {/* Problem Cause */}
        <GridItem colSpan={4}>
          <Text as="b"> Cause (Reason): </Text>
          {problem.problem_cause != ""
            ? problem.problem_cause
            : "No Discription"}
        </GridItem>
        {/* Solution */}
        <GridItem colSpan={4}>
          <Text as="b"> Solution: </Text>
          {problem.problem_solution != ""
            ? problem.problem_solution
            : "No Discription"}
        </GridItem>
        <GridItem colSpan={4}>
          <Center>
            <Divider
              w={"80%"}
              borderColor={"#000000"}
              orientation="horizontal"
            />
          </Center>
        </GridItem>
        <GridItem colSpan={4}>
          <Text as="b"> Recommended Checklist </Text>
          <br />
          {(!problem.recommended_checklist ||
            problem.recommended_checklist.length == 0) && (
            <>
              There Are No Recommended Checklist For This Problem Uploaded By
              System Admin
            </>
          )}
        </GridItem>
        {/* Problem Discription */}
        {(
          problem.recommended_checklist ?? ([] as ProblemRecommendedCheck[])
        ).map((cl, index) => (
          <div key={cl.id + index}>
            <GridItem colSpan={4}>
              <Text as="b"> Question : </Text>
              <br />
              {cl.question && cl.question != ""
                ? cl.question
                : "No Avaliable Questions!"}
            </GridItem>
            <GridItem colSpan={4}>
              <Text as="b"> File : </Text>
              <br />
              <a
                href={`${process.env.REACT_APP_BACK_END_IMG_LINK}${cl.file}`}
                target="_blank"
                rel="noopener noreferrer"
                color="blue"
                style={{ color: "blue", textDecoration: "underline" }}
              >{`${process.env.REACT_APP_BACK_END_IMG_LINK}${cl.file}`}</a>
            </GridItem>
          </div>
        ))}
      </Grid>
    </Box>
  );
};

export default DeviceProblemWidget;
