// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
// Assets
import { MdUpload } from "react-icons/md";
import Dropzone from "views/admin/profile/components/Dropzone";

const Upload = (props: {
  onUpload: (file: File) => void;
  used?: number;
  total?: number;
  title?:string;
  subTitle?:string;
  onClick?: () => void;
  [x: string]: any;
}) => {
  const { used, total, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  return (
    <Card
      {...rest}
      mb="20px"
      justifyItems={"center"}
      justifyContent={"center"}
      alignItems="center"
      onClick={props.onClick}
      height={"100px"}
      p="20px"
    >
      <Flex
        h="100%"
        direction={{ base: "column", "2xl": "row" }}
        w={{ base: "70%", md: "100%" }}
      >
        <Dropzone
          me="36px"
          maxH={{ base: "60%", lg: "50%", "2xl": "100%" }}
          minH={{ base: "190px", lg: "190px", "2xl": "100%" }}
          onUpload={props.onUpload}
          content={
            <Box p={{ base: "25px" }} whiteSpace={"pre-wrap"}>
              <Icon
                as={MdUpload}
                w={{ md: "80px", base: "50px" }}
                h={{ md: "80px", base: "50px" }}
                color={brandColor}
              />
              <Flex justify="center" mx="auto" mb="12px">
                <Text fontSize="xl" fontWeight="700" color={brandColor}>
                  {props.title ?? 'Upload Files'}
                </Text>
              </Flex>
              <Text
                flexWrap={"wrap"}
                fontSize="sm"
                fontWeight="500"
                color="secondaryGray.500"
              >
                {props.subTitle ?? 'PNG, JPG and GIF files are allowed'}
              </Text>
            </Box>
          }
        />
      </Flex>
    </Card>
  );
};
export { Upload };
