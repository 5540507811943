import { Box, Center } from "@chakra-ui/react";
import Card from "components/card/Card";
import * as React from "react";

export interface ITableCardSkeltonProps {
  isErrorSkelton?: boolean;
  message?: string;
}

export function TableCardSkelton(props: ITableCardSkeltonProps) {
  return (
    <Box
      id="table-card-skelton"
      pt={{ base: "50px", md: "0px" }}
      mt={{ xl: "70px" }}
      minH="100%"
      minW="100%"
      flexGrow={1}
      display={"flex"}
    >
      <Card
        className="test"
        flexDirection="column"
        w="100%"
        px="0px"
        minH="100%"
        flexGrow={1}
        justifyContent={"center"}
        alignItems={"center"}
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Box>
          <Center> {props.message ?? "Loading..."}</Center>
        </Box>
      </Card>
    </Box>
  );
}
