import axios from "axios";
import { useMutation } from "react-query";

const createSurveyQuestionAnswers = async (data: CreateSurveyAnswer[]) => {
    return (await axios.post<any>("surveys/answers/bulk_create/", data))
        .data;
};

interface IUseReportSubmitterProps {
    onError?: () => void;
    onSuccess?: () => void;
}
/**
 *
 */
const useSurveySubmitter = (props: IUseReportSubmitterProps) => {
    const {
        onError,
        onSuccess,
    } = props;

    const {
        mutate: createSurveyAnswers,
        isLoading: isCreatingSurveyAnswers,
        isError: isErrorCreatingSurveyAnswers,
    } = useMutation(['create-survey'], createSurveyQuestionAnswers, {

        onError: onError,
        onSuccess: onSuccess,
    });
    return { createSurveyAnswers, isCreatingSurveyAnswers, isErrorCreatingSurveyAnswers };
};

export default useSurveySubmitter;