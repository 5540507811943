import { Box, Flex } from "@chakra-ui/react";
import { PaginationState } from "common/models";
import AdminPendingOrdersTable from "components/data-tables/admin-pending-orders-table";
import { TableCardSkelton } from "components/skeltons/table-card-skelton";
import useOrders from "hooks/data-hooks/useOrders";
import * as React from "react";
import { useState } from "react";

export interface IInitializedOrdersProps {}

export default function InitializedOrders(props: IInitializedOrdersProps) {
  const [initializedOrdersPageState, setInitializedOrdersPageState] = useState<PaginationState>({
    page: 1,
    perPage: 20,
    count: 0,
  });
  const {
    orders: initializedOrders,
    isLoadingOrders: isLoadingInitializedOrders,
    isErrorOrders: isErrorInitializedOrders,
    loadingOrdersError: loadingInitializedOrdersErrors,
    refetchOrders: refetchInitializedOrders,
  } = useOrders({
    customQueryKeyPrefix: "InitializedOrders",
    page: initializedOrdersPageState.page,
    pageSize: initializedOrdersPageState.perPage,
    orderStates: ["INITIALIZED"],
    clientId: undefined,
    onSuccess: (data) => setInitializedOrdersPageState({ ...initializedOrdersPageState, count: data.count }),
  });
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex align="center" gap={"10px"} direction={{ base: "column", md: "column" }}>
        {isLoadingInitializedOrders ? (
          <TableCardSkelton />
        ) : isErrorInitializedOrders ? (
          <TableCardSkelton
            message={
              loadingInitializedOrdersErrors?.response?.data?.message ??
              "Un Expected Error Happen While Loading Initialized Orders!"
            }
            isErrorSkelton={true}
          />
        ) : (
          <AdminPendingOrdersTable
            onOrderUpdated={() => {
              refetchInitializedOrders();
            }}
            tableData={
              initializedOrders?.results.map((r) => ({
                title: r.title,
                state: r.state,
                description: r.description,
                client: r.client.name,
                device: r.device.name,
                order_number: r.order_number,
                order_contact: r.order_contact.map((t) => t.contact_name),

                technical: r?.technical?.map((t) => t.name),
                id: r.id,
              })) || []
            }
            pagination={initializedOrdersPageState}
            setPagination={setInitializedOrdersPageState}
            isLoading={isLoadingInitializedOrders}
          />
        )}
      </Flex>
    </Box>
  );
}
