import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = configureStore({
    reducer: reducer
});

const { dispatch } = store;
export type RootState = ReturnType<typeof store.getState>

export { store, dispatch };
