import { Box, Flex } from "@chakra-ui/react";
import CustomersTable from "components/data-tables/customers-table";
import { TableCardSkelton } from "components/skeltons/table-card-skelton";
import useCustomers from "hooks/data-hooks/useCustomers";
import React, { useState } from "react";

const Customers = () => {
  const [customersPageState, setCustomersPageState] = useState({
    page: 1,
    perPage: 10,
    count: 0,
  });

  const { customers, isLoadingCustomers, isErrorCustomers, loadingCustomersError } = useCustomers({
    page: customersPageState.page,
    pageSize: customersPageState.perPage,
    onLoadCustomersSuccess: (data) => setCustomersPageState({ ...customersPageState, count: data.count }),
  });

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex align="center" gap={"10px"} direction={{ base: "column", md: "column" }}>
        {isLoadingCustomers ? (
          <TableCardSkelton />
        ) : isErrorCustomers ? (
          <TableCardSkelton
            message={
              loadingCustomersError?.response?.data?.message ?? "Un Expected Error Happen While Loading Customers!"
            }
            isErrorSkelton={true}
          />
        ) : (
          <CustomersTable
            tableData={
              customers?.results?.map((r) => ({
                company_name: r.company_name,
                manager: r.manager,
                technical_manger: r.technical_manger,
                address: r.address,
                phone_number: r.phone_number,
                manager_mobile: r.manager_mobile,
                email: r.email,
                website: r.website,
                facebook: r.facebook,
                serial_number: r.serial_number,
                logo: r.logo,
              })) || []
            }
            pagination={customersPageState}
            setPagination={setCustomersPageState}
            isLoading={isLoadingCustomers}
          />
        )}
      </Flex>
    </Box>
  );
};

export default Customers;
