import moment from "moment";
import { Route } from "react-router-dom";
import * as React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export type USER_RULE_TYPE = "ADMIN" | "CLIENT" | "TECHNICAL";
export enum LOCAL_STORAGE_KEYS {
  ACCESS_TOKEN = "AUTH_ACCESS_TOKEN",
  EXPIRES_IN = "AUTH_EXPIRES_IN",
  USER_TYPE = "USER_RULE_TYPE",
  USER_ID = "USER_ID",
}

export const getToken = (): string => {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || "";
};
export const getHeader = (image?: boolean) => {
  if (image)
    return {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "multipart/form-datan",
    };

  return {
    Authorization: "Bearer " + getToken(),
    "Content-Type": "application/json",
  };
};
export const isLoggedIn = () => {
  // TODO : delete this function 
  return isLoggedIn;
  // const tokenExpiration = localStorage.getItem(LOCAL_STORAGE_KEYS.EXPIRES_IN);
  // if (!tokenExpiration) return false;
  // const isTokenStillValid = moment(moment(Date.now()).toString()).isBefore(
  //   moment(tokenExpiration)
  // );
  // const sss = Number(Date.now()) + Number(tokenExpiration);
  // console.log(tokenExpiration);

  // return isTokenStillValid;
};

export const getYearsBins = (from: number) => {
  const currentYear = (new Date()).getFullYear();
  const range = (start: number, stop: number, step: number) =>
    Array.from({
      length: (stop - start) / step + 1
    }, (_, i) => start + (i * step));
  return range(currentYear, from, -1);
}

export const toPascalCase = (s: string) => {
  return s.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};

export const getActiveNavbar = (routes: RoutesType[]): boolean => {
  let activeNavbar = false;
  for (let i = 0; i < routes.length; i++) {
    if (
      window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
    ) {
      return routes[i].secondary;
    }
  }
  return activeNavbar;
};


export const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
  let activeNavbar = false;
  for (let i = 0; i < routes.length; i++) {
    if (
      window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
    ) {
      return routes[i].name;
    }
  }
  return activeNavbar;
};
export const getActiveRoute = (routes: RoutesType[]): string => {
  let activeRoute = "Default Brand Text";
  for (let i = 0; i < routes.length; i++) {
    //console.log('window.location.href : ' + window.location.href)
    //console.log('routes[i].layout + routes[i].path' + routes[i].layout + routes[i].path)

    if (
      window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
    ) {
      return routes[i].name;
    }
  }
  return activeRoute;
};

export const getRoute = (subPath: string) => {
  return window.location.pathname !== `${subPath}/full-screen-maps`;
};

export const convertToTitleCase = (input: string) => {
  return input
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const formalizeError = (errorData: { [key: string]: string[] }) => {
  // Initialize an empty array to store formatted strings
  const formattedOutput = [];

  for (const prop in errorData) {
    if (errorData.hasOwnProperty(prop)) {
      const propertyName = convertToTitleCase(prop);
      const errors = errorData[prop];
      if (errors.length > 0) {
        const errorString = `${propertyName} ${errors.join(" ")}`;
        formattedOutput.push(errorString);
      }
    }
  }

  // Join the formatted strings with a separator (e.g., ', ')
  const result = formattedOutput.join(", ");
  return result;
}

export const formalizeCostDetails = (costDetails: { [key: string]: string }) => {
  // Initialize an empty array to store formatted strings
  const formattedOutput = [];
  for (const prop in costDetails) {
    if (costDetails.hasOwnProperty(prop)) {
      const propertyName = convertToTitleCase(prop);
      const details = costDetails[prop];
      if (details.length > 0) {
        const errorString = `${propertyName} ${details}`;
        formattedOutput.push(errorString);
      }
    }
  }

  // Join the formatted strings with a separator (e.g., ', ')
  const result = formattedOutput.join(", ");
  return result;
}

export const exportToExcel = (data:any[]) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Buffer to store the generated Excel file
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

  saveAs(blob, "data.xlsx");
};