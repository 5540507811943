/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext, useEffect, useState } from "react";
import {
  NavLink,
  useLocation,
  useNavigate,
  useNavigationType,
} from "react-router-dom";
import { toast } from "react-toastify";

// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import useAuth from "hooks/useAuth";
import axios from "axios";
import jwtDecode from "jwt-decode";
//import { setExpiresIn, setRule, setToken, setUser } from "services/authManager";
type loginResponce = {
  access_token: string;
  refresh_token: string;
  user: {
    pk: string;
    role: string;
    username: string;
  };
  email: string;
  pk: string;
  role: string;
  username: string;
};
function SignIn() {
  const auth = useAuth();
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginErrorMessage, setLoginErrorMessage] = useState(undefined);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isChecking, setIsChecking] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  let from = location.state?.from?.pathname;
  useEffect(() => {
    // TODO : Review This
    //console.log("Login useEffect")
    if (!auth.isThereStoredSession()) {
      setIsChecking(false);
      return;
    }
    auth.verifyAccessToken((isValid, error) => {
      if (error) {
        //setIsChecking(false);
        auth.refreshTokens((_, error) => {
          const rule = auth.getRule();
          if (error) {
            setIsChecking(false);
            return;
          }
          navigate(from??`/${rule.toLowerCase()}/home`);
        });
      }
      if (isValid) {
        const rule = auth.getRule();
        auth.intercept();
        navigate(from??`/${rule.toLowerCase()}/home`);
        return;
        //interceptAxiosDefaultHttpClient();
      }
    });
  }, []);
  const handelLogin = async () => {
    setLoginErrorMessage(undefined);
    auth.signin(email, password, (data, error) => {
      if (error != null) {
        // TODO : set error message
        setLoginErrorMessage(error.message);
        return;
      }
      const rule = auth.getRule();
      //setRule(rule);
      auth.intercept();
      navigate(from??`/${rule.toLowerCase()}/home`);
    });
  };

  function handleClick(): void {
    throw new Error("Function not implemented.");
  }

  if (isChecking) {
    return <>Checking Your Connection!</>;
  }
  return (
    <DefaultAuth image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl isInvalid={loginErrorMessage}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email <Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              placeholder="mail@simmmple.com"
              mb="24px"
              fontWeight="500"
              size="lg"
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb="12px"
                size="lg"
                type={isShowPassword ? "text" : "password"}
                variant="auth"
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={isShowPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={() => setIsShowPassword(!isShowPassword)}
                />
              </InputRightElement>
            </InputGroup>
            {loginErrorMessage && (
              <FormErrorMessage
                ms="4px"
                mb="8px"
                fontSize="md"
                fontWeight="500"
              >
                Login Failed : {loginErrorMessage}
              </FormErrorMessage>
            )}
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              disabled={auth.isLoggingIn || email == "" || password == ""}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={() => handelLogin()}
            >
              {auth.isLoggingIn ? "Signing In..." : "Sign In"}
            </Button>
          </FormControl>
          {/* <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Not registered yet?
              <NavLink to="/auth/sign-up">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex> */}
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
