import axios from "axios";
import { useMutation } from "react-query";

const createOrder = async (data: CreateOrderInput) => {
  var formData = new FormData();
  if (data.title) {
    formData.append("title", data.title);
  }
  if (data.state) {
    formData.append("state", data.state);
  }
  if (data.description) {
    formData.append("description", data.description);
  }

  formData.append("client", data.client);
  formData.append("device", data.device);
  if (data.order_number) {
    formData.append("order_number", data.order_number);
  }
  formData.append("order_work_status", data.order_work_status);
  formData.append("order_type", data.order_type);
  formData.append("order_contact", data.order_contact);
  if (data.technical) {
    data.technical?.map((e) => formData.append("technical", e));
  }
  data.problem_images?.map((e) => formData.append("problem_images", e));
  if (data.scheduled_visit_date) {
    formData.append("scheduled_visit_date", data.scheduled_visit_date);
  }
  formData.append("service_details", data.service_details);
  formData.append("device_status", data.device_status);
  formData.append("frequency_of_occurrence", data.frequency_of_occurrence.toString());
  formData.append("date_occurrence", data.date_occurrence);
  formData.append("pdf_file", data.pdf_file);
  formData.append("comment", data.comment);

  return (await axios.post("orders/", formData)).data;
};

const createOrderServiceDetails = async (data: CreateServiceDetailInput) => {
  return (await axios.post<ServiceDetail>("services/service-details/", data))
    .data;
};

const createTroubleshootingGuide = async (
  deviceId: string,
  data: PatchTroubleshootingGuideInput
) => {
  return (
    await axios.put<TroubleshootingGuide>(
      `devices/troubleshooting-guide/${deviceId}/`,
      data
    )
  ).data;
};

const createOrderProblemImages = async (
  orderProblemImagesCreateInput: OrderProblemImagesCreateInput
) => {
  const imagesForm = new FormData();
  orderProblemImagesCreateInput.problem_images.map((img, index) => {
    imagesForm.append("problem_images", img);
    imagesForm.append(
      "comments",
      orderProblemImagesCreateInput.comments[index]
    );
  });
  return (
    await axios.post<OrderProblemImages>(
      `orders/order-problem-images`,
      imagesForm
    )
  ).data;
};

interface IUseOrderSubmitterProps {
  createServiceDetailInput?: CreateServiceDetailInput;
  orderProblemImagesCreateInput?: OrderProblemImagesCreateInput;
  createOrderInput?: CreateOrderInput;
  tsgId: string;
  problemSelected: string;
  onError?: () => void;
  onSuccess?: () => void;
}
/**
 *
 */
const useOrderSubmitter = (props: IUseOrderSubmitterProps) => {
  const {
    tsgId,
    problemSelected,
    createServiceDetailInput,
    orderProblemImagesCreateInput,
    createOrderInput,
    onError,
    onSuccess,
  } = props;

  const createOrderPipeline = () =>
    createOrderServiceDetails(createServiceDetailInput).then(
      (serviceDetail) => {
        return createTroubleshootingGuide(tsgId, {
          problem_selected: problemSelected,
        })
          .then(() => {
            return createOrderProblemImages(orderProblemImagesCreateInput);
          })
          .then((orderProblemImages) => {
            return createOrder({
              ...createOrderInput,
              service_details: serviceDetail.id,
              problem_images: orderProblemImages.problem_images,
            } as CreateOrderInput);
          });
      }
    );
  const {
    mutate: create,
    isLoading: isCreatingOrder,
    isError: isErrorCreatingOrder,
  } = useMutation(createOrderPipeline, {
    onError: onError,
    onSuccess: onSuccess,
  });
  return { create, isCreatingOrder, isErrorCreatingOrder };
  /*createOrderServiceDetails(createServiceDetailsInput).then((serviceDetail));
  await createTroubleshootingGuide(tsgId, {
    problem_selected: problemSelected,
  });
  const orderProblemImages = await createOrderProblemImages(
    orderProblemImagesCreateInput
  );
  await createOrder({
    ...createOrderInput,
    service_details: serviceDetail.id,
    problem_images:orderProblemImages.problem_images
  } as CreateOrderInput);*/
};

export default useOrderSubmitter;
