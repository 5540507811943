import { Center, FormLabel, HStack, Select } from "@chakra-ui/react";
import useReportTemplates from "hooks/data-hooks/useReportTemplates";
import { toPascalCase } from "utilities/utilities";
import { TemplateFactory } from "../template-factory/template-factory";

export interface IReportDetailsTabProps {
  createReportInput: CreateServiceReportInput;
  onCreateReportInputChange: (newValues: CreateServiceReportInput) => void;
}

export default function ReportDetailsTab(props: IReportDetailsTabProps) {
  const { createReportInput, onCreateReportInputChange } = props;
  const { templates, isLoadingTemplates, isErrorLoadingTemplates } =
    useReportTemplates(true);
  const TemplateForm = TemplateFactory.hasOwnProperty(
    createReportInput.service_template_name
  )
    ? (TemplateFactory as any)[createReportInput.service_template_name]
    : () => <Center>Please Select Template</Center>;

  if (isLoadingTemplates) {
    return <>Loading!</>;
  }

  if (isErrorLoadingTemplates) {
    return <>Error!</>;
  }
  return (
    <>
      <HStack mb={"10px"}>
        <FormLabel marginRight={"20px"}>Template</FormLabel>
        <Select
          placeholder="Select option"
          onChange={(e) => {
            onCreateReportInputChange({
              ...createReportInput,
              service_template_name: e.target.value,
            });
          }}
        >
          {templates.map((t) => (
            <option key={t} value={t}>
              {toPascalCase(t)}
            </option>
          ))}
        </Select>
      </HStack>
      <TemplateForm
        onChange={(data: any) =>
          onCreateReportInputChange({ ...createReportInput, details: data })
        }
      />
    </>
  );
}
