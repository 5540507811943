import { Box, Flex } from "@chakra-ui/react";
import TechniciansTable from "components/data-tables/technicians-table";
import { TableCardSkelton } from "components/skeltons/table-card-skelton";
import useTechnicians from "hooks/data-hooks/useTechnicians";
import { useState } from "react";

const Technicians = () => {
  const [techniciansPageState, setTechniciansPageState] = useState({
    page: 1,
    perPage: 10,
    count: 0,
  });

  const { technicians, isLoadingTechnicians, isErrorTechnicians, loadingTechniciansError } = useTechnicians({
    page: techniciansPageState.page,
    pageSize: techniciansPageState.perPage,
    onLoadTechniciansSuccess: (data) => setTechniciansPageState({ ...techniciansPageState, count: data.count }),
  });

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex align="center" gap={"10px"} direction={{ base: "column", md: "column" }}>
        {isLoadingTechnicians ? (
          <TableCardSkelton />
        ) : isErrorTechnicians ? (
          <TableCardSkelton
            message={
              loadingTechniciansError?.response?.data?.message ?? "Un Expected Error Happen While Loading Technicians!"
            }
            isErrorSkelton={true}
          />
        ) : (
          <TechniciansTable
            tableData={
              technicians?.results?.map((r) => ({
                identity_image: r.identity_image,
                domain: r.domain,
                phone_number: r.phone_number,
                address: r.address,
              })) || []
            }
            pagination={techniciansPageState}
            setPagination={setTechniciansPageState}
            isLoading={isLoadingTechnicians}
          />
        )}
      </Flex>
    </Box>
  );
};

export default Technicians;
