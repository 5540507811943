import axios, { AxiosError } from "axios";
import { PagedResult } from "common/models";
import { useQuery } from "react-query";
import { getHeader } from "utilities/utilities";

const fetchDeviceTsgHistory = async (tsgId: string) => {
    return (await axios.get<PagedResult<TroubleshootingGuideHistory>>(
        "devices/troubleshooting-guide-history",
        {
            params: {
                troubleshooting_guide: tsgId
            },
            headers: getHeader(),
            withCredentials: false
        }
    )).data;
};

/**
 * used to get user device trouble shooting guide history
 * @returns
 */
const useDeviceTsgHistory = (tsgId: string, onSuccess?: (data: PagedResult<TroubleshootingGuideHistory>) => void) => {
    const {
        data: deviceTsgHistory,
        isLoading: isLoadingTsgHistory,
        isError: isErrorTsgHistory,
        error: lopdaingDeviceTsgHistoryError
    } = useQuery<PagedResult<TroubleshootingGuideHistory>, AxiosError<any>>(['device-tsg-history', tsgId], () => fetchDeviceTsgHistory(tsgId), {
        keepPreviousData: true, onSuccess: onSuccess,
        enabled: tsgId && tsgId !== ""
    });
    return { deviceTsgHistory, isLoadingTsgHistory, isErrorTsgHistory, lopdaingDeviceTsgHistoryError };

};

export default useDeviceTsgHistory;