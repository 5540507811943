import { GridItem, Text, Box } from "@chakra-ui/react";
import * as React from "react";

export interface IDeviceCheckListTabProps {
  deviceChecklist: DeviceCheckList;
}

export default function DeviceCheckListTab(props: IDeviceCheckListTabProps) {
  const { deviceChecklist } = props;
  if (!deviceChecklist) {
    return (
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <div>There Are No Checklist Uploaded For This Device By Admin</div>
      </Box>
    );
  }
  return (
    <div>
      <GridItem colSpan={4}>
        <Text as="b"> Question : </Text>
        <br />
        {deviceChecklist.question && deviceChecklist.question != ""
          ? deviceChecklist.question
          : "No Available Questions Entered By Admin To Check This Device!"}
      </GridItem>
      <GridItem colSpan={4}>
        <Text as="b"> File : </Text>
        <br />
        {deviceChecklist.file && deviceChecklist.file != "" ? (
          <a
            href={`${process.env.REACT_APP_BACK_END_IMG_LINK}${deviceChecklist.file}`}
            target="_blank"
            rel="noopener noreferrer"
            color="blue"
            style={{ color: "blue", textDecoration: "underline" }}
          >{`${process.env.REACT_APP_BACK_END_IMG_LINK}${deviceChecklist.file}`}</a>
        ) : (
          "No Available Files Uploaded By Admin To Check This Device!"
        )}
      </GridItem>
    </div>
  );
}
