import { useMutation, useQuery } from "react-query";
import axios, { AxiosError } from "axios";
import { PagedResult } from "common/models";

const fetchDevices = async (
  actor: Actor,
  userId: string,
  page?: number,
  pageSize?: number
) => {
  const url =
    (actor == "technical" || actor == "admin") ? "devices/" : `devices/user-devices/${userId}/`;
  return (
    await axios.get<PagedResult<Device>>(
      url, // TODO : fetch this value
      {
        params: {
          page_size: pageSize,
          page: page,
        },
      }
    )
  ).data;
};

const postDevice = async (data: FormData) => {
  return (await axios.post<void>("devices/user-devices", data)).data;
};

/**
 *
 */
interface IUseDevicesProps {
  actor?: Actor;
  userId?: string;
  page?: number;
  pageSize?: number;
  onLoadDevicesSuccess?: (data: PagedResult<Device>) => void;
  onCreateDeviceSuccess?: () => void;
  onCreateDeviceFailed?: () => void;
}

/**
 * used to get user devices
 * @returns
 */
const useDevices = (props: IUseDevicesProps) => {
  // deconstruction props
  const {
    actor,
    userId,
    page,
    pageSize,
    onLoadDevicesSuccess,
    onCreateDeviceSuccess,
    onCreateDeviceFailed,
  } = props;

  // data fetching useQuery hook (used to fetch devices for devices table)
  const {
    data: devices,
    isLoading: isLoadingDevices,
    isError: isErrorLoadingDevices,
    error: loadingDevicesError,
    refetch:refetchDevices
  } = useQuery<PagedResult<Device>, AxiosError<any>>(
    ["devices", page, pageSize],
    () => fetchDevices(actor, userId, page, pageSize),
    {
      keepPreviousData: true,
      onSuccess: onLoadDevicesSuccess,
      enabled: actor !== undefined,
    }
  );

  // create table useMutation hook (used to create new device by customer)
  const {
    mutate: createDevice,
    isLoading: isCreatingDevice,
    isError: isErrorCreatingDevice,
    error: creatingDeviceError,
  } = useMutation(postDevice, {
    onSuccess: onCreateDeviceSuccess,
    onError: onCreateDeviceFailed,
  });

  // return hooks result
  return {
    devices,
    isLoadingDevices,
    isErrorLoadingDevices,
    loadingDevicesError,
    createDevice,
    isCreatingDevice,
    isErrorCreatingDevice,
    creatingDeviceError,
    refetchDevices
  };
};

export default useDevices;
