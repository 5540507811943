import { Box, Button, ButtonGroup, Icon, IconButton, Image, Stack, Text } from "@chakra-ui/react";
import pdf from "assets/img/files-icons/pdf.png";
import { MdFileDownload } from "react-icons/md";
import * as React from "react";

export interface IOrderAttachmentsTabProps {
  pdfFile?: string;
  comment?: string;
}

export default function OrderAttachmentsTab(props: IOrderAttachmentsTabProps) {
  const { pdfFile, comment } = props;
  if (pdfFile == null || pdfFile == "") {
    return (
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <div>There Are No Uploaded Attachments For This Order</div>
      </Box>
    );
  }
  return (
    <Stack width={"30%"} alignItems={"center"}>
      {/* TODO : add file icon mapper like  */}
      <Image src={pdf} alt="" width={"200px"} />
      <Text>{pdfFile.split("/").pop()}</Text>
      <ButtonGroup
        size="sm"
        isAttached
        variant="outline"
        onClick={async (e) => {
          e.stopPropagation();
          const fileUrl = pdfFile;
          window.open(fileUrl, "_blank");
        }}
      >
        <Button>Download</Button>
        <IconButton aria-label="Add to friends" icon={<MdFileDownload />} />
      </ButtonGroup>
    </Stack>
  );
}