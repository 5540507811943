import { ExternalLinkIcon, DeleteIcon } from "@chakra-ui/icons";
import pdf from "assets/img/files-icons/pdf.png";
// import DatePicker from "react-datepicker";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Image,
  Text,
  useColorModeValue,
  Select,
  FormErrorMessage,
  IconButton,
  Stack,
  GridItem,
  Grid,
  useDisclosure,
  FormHelperText,
  Textarea,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { Upload } from "../../../components/upload/upload";
import { useEffect, useState } from "react";
import { AuthContext } from "contexts/AuthContext";
import { json, useNavigate } from "react-router-dom";
import { getYearsBins } from "utilities/utilities";
import TextFiled from "components/textFiled/TextFiled";
import { useFormik } from "formik";
import useDevices from "hooks/data-hooks/useDevices";
import { toast } from "react-toastify";
import * as Yup from "yup";
import FormikYupInputField from "components/formik-yup-input-field/formik-yup-input-field";
import ImageModal from "components/image-modal";
import { validDeviceNames } from "consts/const";

function AddDevice() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState(undefined);
  // Chakra Color Mode
  const [images, setImages] = useState<File[]>([]);
  const [pdfFile, setPdfFile] = useState<File>(null);
  const navigate = useNavigate();
  const textColor = useColorModeValue("navy.700", "white");
  const bg = useColorModeValue("white", "navy.700");
  const validationSchema = Yup.object().shape({
    device_name: Yup.string().required("Device name is required"),
    serial_number: Yup.string().required("Device serial number is required"),
    manufacturing_date: Yup.string().required("Device manufacturing date is required"),
    kw_power: Yup.number().typeError("Must Be Number").nullable().min(0, "Power Must Be Positive"),
    work_rate: Yup.string().nullable().typeError("Duty Cycle Must Be Number"),
    device_model: Yup.string().required("Device model is required"),
    device_type: Yup.string().required("Device type is required"),
    device_images: Yup.array().required("Device images are required"),
    part_no: Yup.string().required("Part No is required"),
    max_pressure: Yup.number().typeError("Must Be Number").required("Max Pressure is required"),
    run_hours: Yup.number().typeError("Must Be Number").required("Run Hours is required"),
    load_hours: Yup.number().typeError("Must Be Number").required("Load Hours is required"),
  });
  const years = getYearsBins(1980);
  const { createDevice, isCreatingDevice } = useDevices({
    onCreateDeviceSuccess: () => {
      toast.success("Device Created Successfully");
      navigate("../overview");
    },
    onCreateDeviceFailed: () => {
      toast.error("Failed To Create This Device!");
    },
  });
  const formik = useFormik<CreateDeviceInput>({
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: {
      device_name: "",
      serial_number: "",
      manufacturing_date: "",
      startup_date: new Date().toISOString().slice(0, 10),
      device_model: "",
      device_type: "",
      work_rate: "",
      kw_power: 0,
      device_images: [],
      part_no: "",
      comment: "",
      max_pressure: 0,
      run_hours: 0,
      load_hours: 0,
    },
    onSubmit: (values) => {
      //console.log("submit!!");
      const formData = new FormData();
      // TODO : ADD MAXIMUM IMAGES SIZE CONDITION)
      // TODO : REVIEW VALIDATION SCHEMA
      const year = Number(formik.values.manufacturing_date);
      let iso8601Date;
      if (year !== -1) {
        iso8601Date = new Date(year, 1, 1).toISOString();
      } else {
        iso8601Date = null;
      }
      formData.append("device_name", formik.values.device_name);
      formData.append("serial_number", formik.values.serial_number);
      formData.append("manufacturing_date", iso8601Date);
      formData.append("startup_date", formik.values.startup_date.toString());
      formData.append("device_model", formik.values.device_model);
      formData.append("device_type", formik.values.device_type);
      formData.append("work_rate", formik.values.work_rate);
      formData.append("power", formik.values.kw_power.toString());
      formData.append("part_no", formik.values.part_no.toString());
      formData.append("max_pressure", formik.values.max_pressure.toString());
      formData.append("run_hours", formik.values.run_hours.toString());
      formData.append("load_hours", formik.values.load_hours.toString());
      formData.append("comment", formik.values.comment.toString());
      formData.append("pdf_file", pdfFile);
      images.map((e) => formData.append(`device_images`, e));
      createDevice(formData);
    },
    validationSchema: validationSchema,
  });

  const deleteImage = async (index?: number) => {
    if (window.confirm("Do You Want To Delete This Image")) {
      const temp = images.filter((img, i) => i != index);
      setImages(temp);
    }
  };

  const isErrorDeviceName = formik.touched.device_name && formik.errors.device_name;
  const isErrorSerialNumber = formik.touched.serial_number && formik.errors.serial_number;
  const isErrorManufacturingDate = formik.touched.manufacturing_date && formik.errors.manufacturing_date;
  const isErrorStartupDate = formik.touched.startup_date && formik.errors.startup_date;
  const isErrorDeviceModel = formik.touched.device_model && formik.errors.device_model;
  const isErrorWorkRate = formik.touched.work_rate && formik.errors.work_rate;
  const isErrorPower = formik.touched.kw_power && formik.errors.kw_power;
  const isErrorDeviceType = formik.touched.device_type && formik.errors.device_type;
  const isErrorPartNo = formik.touched.part_no && formik.errors.part_no;
  const isErrorLoadHours = formik.touched.load_hours && formik.errors.load_hours;
  const isErrorRunHours = formik.touched.run_hours && formik.errors.run_hours;
  const isErrorMaxPressure = formik.touched.max_pressure && formik.errors.max_pressure;
  const isErrorDeviceImages = formik.touched.device_images && formik.errors.device_images;

  useEffect(() => {
    let timer1 = setTimeout(() => formik.validateForm(), 50);
    // this will clear Timeout
    // when component unmount like in willComponentUnmount
    // and show will not change to true
    return () => {
      clearTimeout(timer1);
    };
  }, [JSON.stringify(formik.values)]);
  return (
    <>
      <Box pt={{ base: "50px", md: "0px" }} mt={{ xl: "70px" }}>
        <Card mb={{ base: "0px", "2xl": "20px" }}>
          <Text color={textColor} fontSize="2xl" fontWeight="700">
            Your device information :
          </Text>
          <FormControl>
            <Flex direction={{ sm: "column", xl: "row" }} gap="15px" my={"20px"}>
              <FormikYupInputField
                label="Device Name : "
                type="text"
                formikField="device_name"
                formikValue={formik.values.device_name}
                yupValidationErrors={isErrorDeviceName}
                formikHandleChange={formik.handleChange}
                formikSetFieldTouched={formik.setFieldTouched}
                // helperText="Enter the name of device (ex. DeskJet 2545)."
                InputElement={(props: any) => (
                  <Select value={props.value} name={props.name} onChange={props.onChange} placeholder="Select option">
                    {validDeviceNames.map((y) => (
                      <option value={y}>{y}</option>
                    ))}
                  </Select>
                )}
                // helperText="Enter the manufacturing date of device (ex. 2020)."
              />
              <FormikYupInputField
                label="Device Model : "
                type="text"
                formikField="device_model"
                formikValue={formik.values.device_model}
                yupValidationErrors={isErrorDeviceModel}
                formikHandleChange={formik.handleChange}
                formikSetFieldTouched={formik.setFieldTouched}
                // helperText="Enter the model of device (ex. HP)."
              />
              <FormikYupInputField
                label="Device Type : "
                type="text"
                formikField="device_type"
                formikValue={formik.values.device_type}
                yupValidationErrors={isErrorDeviceType}
                formikHandleChange={formik.handleChange}
                formikSetFieldTouched={formik.setFieldTouched}
                // helperText="Enter the type of device (ex. Printer)."
              />
              <FormikYupInputField
                label="Serial Number : "
                type="text"
                formikField="serial_number"
                formikValue={formik.values.serial_number}
                yupValidationErrors={isErrorSerialNumber}
                formikHandleChange={formik.handleChange}
                formikSetFieldTouched={formik.setFieldTouched}
                // helperText="Enter the serial number S/N (ex. 4CE0460D0G)."
              />
            </Flex>
            <Flex direction={{ sm: "column", xl: "row" }} gap="15px" my={"20px"}>
              {/* TODO : replace with select */}
              <FormikYupInputField
                label="Manufacturing Date : "
                type="text"
                formikField="manufacturing_date"
                formikValue={formik.values.manufacturing_date}
                yupValidationErrors={isErrorManufacturingDate}
                formikHandleChange={formik.handleChange}
                formikSetFieldTouched={formik.setFieldTouched}
                InputElement={(props: any) => (
                  <Select value={props.value} name={props.name} onChange={props.onChange} placeholder="Select option">
                    {years.map((y) => (
                      <option value={y}>{y}</option>
                    ))}
                  </Select>
                )}
                // helperText="Enter the manufacturing date of device (ex. 2020)."
              />
              {/* <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Manufacturing Date<Text color={brandStars}>*</Text>
                </FormLabel>
                <Select
                  value={formik.values.manufacturing_date}
                  name={"manufacturing_date"}
                  onChange={formik.handleChange}
                  placeholder="Select option"
                >
                  {years.map((y) => (
                    <option value={y}>{y}</option>
                  ))}
                </Select>
              </FormControl>{" "} */}
              <FormControl>
                <FormLabel>Startup Date :</FormLabel>
                <Input
                  type={"date"}
                  required={true}
                  isRequired={true}
                  pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z"
                  value={formik.values.startup_date?.slice(0, 10)}
                  //max={new Date().toISOString().slice(0, 10)}
                  onChange={(e) => {
                    if (!e.target.value) {
                      return;
                    }
                    const [y, m, d] = e.target.value.split("-");
                    const iso8601Date = new Date(Number(y), Number(m) - 1, Number(d) + 1).toISOString();
                    formik.setFieldValue("startup_date", iso8601Date);
                  }}
                />
                {isErrorStartupDate && <FormErrorMessage>{isErrorStartupDate}</FormErrorMessage>}
              </FormControl>

              <FormikYupInputField
                label="Duty Cycle : "
                type="string"
                formikField="work_rate"
                formikValue={formik.values.work_rate}
                yupValidationErrors={isErrorWorkRate}
                formikHandleChange={formik.handleChange}
                formikSetFieldTouched={formik.setFieldTouched}
                // helperText="Enter the duty cycle (ex. 7.5)."
              />
              <FormikYupInputField
                label="Power (Kw) : "
                type="number"
                formikField="kw_power"
                formikValue={formik.values.kw_power}
                yupValidationErrors={isErrorPower}
                formikHandleChange={formik.handleChange}
                formikSetFieldTouched={formik.setFieldTouched}
                // helperText="Enter power of device in kilo watt (ex. 1.2)."
              />
            </Flex>
            <Flex direction={{ sm: "column", xl: "row" }} gap="15px" my={"20px"}>
              <FormikYupInputField
                label="Part No. : "
                type="string"
                formikField="part_no"
                formikValue={formik.values.part_no}
                yupValidationErrors={isErrorPartNo}
                formikHandleChange={formik.handleChange}
                formikSetFieldTouched={formik.setFieldTouched}
                // helperText="Enter Device Part No. (ex. 775SF)."
              />
              <FormikYupInputField
                label="Bar Max Pressure : "
                type="number"
                formikField="max_pressure"
                formikValue={formik.values.max_pressure}
                yupValidationErrors={isErrorMaxPressure}
                formikHandleChange={formik.handleChange}
                formikSetFieldTouched={formik.setFieldTouched}
                // helperText="Enter Device Max Pressure (ex. 1.2)."
              />
              <FormikYupInputField
                label="Run Hours : "
                type="number"
                formikField="run_hours"
                formikValue={formik.values.run_hours}
                yupValidationErrors={isErrorRunHours}
                formikHandleChange={formik.handleChange}
                formikSetFieldTouched={formik.setFieldTouched}
                // helperText="Enter Device Run Hours (ex. 5)."
              />
              <FormikYupInputField
                label="Load Hours : "
                type="number"
                formikField="load_hours"
                formikValue={formik.values.load_hours}
                yupValidationErrors={isErrorLoadHours}
                formikHandleChange={formik.handleChange}
                formikSetFieldTouched={formik.setFieldTouched}
                // helperText="Enter Device Load Hours (ex. 3)."
              />
            </Flex>
            <Flex direction={{ sm: "column", xl: "row" }} gap="15px" my={"20px"}>
              <FormControl>
                <FormikYupInputField
                  InputElement={Textarea}
                  label="Comment : "
                  type="text"
                  formikField="comment"
                  formikValue={formik.values.comment}
                  yupValidationErrors={null}
                  formikHandleChange={formik.handleChange}
                  formikSetFieldTouched={formik.setFieldTouched}
                  // helperText="Enter Comment About Your Device (Optional)."
                />
              </FormControl>
            </Flex>
            <Card
              bg={bg}
              display={"flex"}
              justifyItems={"center"}
              justifyContent={"center"}
              alignItems="flex-start"
              gap={"15px"}
            >
              <Text color={textColor} fontSize="34px" textAlign="start" fontWeight="700" lineHeight="100%">
                Device images
              </Text>
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                  lg: "repeat(3, 1fr)",
                  xl: "repeat(4, 1fr)",
                }}
                mx="auto"
                gap={{ base: 5, md: 8 }}
                p={4}
              >
                {images.map((e, index) => {
                  const imageUrl = URL.createObjectURL(e);
                  return (
                    <GridItem key={index}>
                      <Flex direction="column" align="center">
                        <Image
                          borderRadius={"10px"}
                          style={{ aspectRatio: "4/3" }}
                          width={{ base: "100px", md: "200px", xl: "250px" }}
                          src={imageUrl}
                          objectFit="cover"
                        />
                        <Text as="b" mt={2}>
                          {e.name}
                        </Text>
                        <Stack spacing={2} direction={"row"}>
                          <IconButton
                            aria-label="delete"
                            colorScheme="red"
                            icon={<DeleteIcon />}
                            onClick={() => deleteImage(index)}
                          />
                          <IconButton
                            aria-label="expand"
                            colorScheme="blue"
                            icon={<ExternalLinkIcon />}
                            onClick={() => {
                              onOpen();
                              setSelectedImage({ name: e.name, url: imageUrl });
                            }}
                          />
                        </Stack>
                      </Flex>
                    </GridItem>
                  );
                })}
              </Grid>
              <Upload
                onClick={() => {
                  formik.setFieldTouched("device_images");
                }}
                //@ts-ignore
                onUpload={(file: File) => {
                  setImages([...images, file]);
                  /*formik.setFieldValue("device_images", [
                    ...formik.values.device_images,
                    file,
                  ]);*/
                }}
                maxW={"500px"}
                justifyItems={"center"}
                justifyContent={"center"}
                gridArea={{
                  base: "3 / 1 / 4 / 2",
                  lg: "1 / 3 / 2 / 4",
                }}
                minH={{ base: "auto", lg: "420px", "2xl": "365px" }}
                pe="20px"
                pb={{ base: "100px", lg: "20px" }}
              />
              {isErrorDeviceImages && <FormErrorMessage>{isErrorDeviceImages as string}</FormErrorMessage>}
            </Card>

            {/* Pdf File */}
            <Card
              bg={bg}
              display={"flex"}
              justifyItems={"center"}
              justifyContent={"center"}
              alignItems="flex-start"
              gap={"15px"}
            >
              <Text color={textColor} fontSize="34px" textAlign="start" fontWeight="700" lineHeight="100%">
                Pdf File
              </Text>
              {pdfFile != null ? (
                <Stack alignItems={"center"}>
                  <Image src={pdf} alt="" width={"100px"} />
                  <Text as="b" mt={2}>
                    {pdfFile.name}
                  </Text>
                  <IconButton
                    aria-label="delete"
                    colorScheme="red"
                    icon={<DeleteIcon />}
                    onClick={() => {
                      if (window.confirm("Do You Want To Delete This File")) {
                        setPdfFile(null);
                      }
                    }}
                  />
                </Stack>
              ) : (
                <Upload
                  onClick={() => {
                    formik.setFieldTouched("pdf_file");
                    formik.setFieldTouched("comment");
                  }}
                  title="Upload File PDF"
                  subTitle="Only PDF Files Are Allowed"
                  //@ts-ignore
                  onUpload={(file: File) => {
                    setPdfFile(file);
                    /*formik.setFieldValue("device_images", [
                    ...formik.values.device_images,
                    file,
                  ]);*/
                  }}
                  maxW={"500px"}
                  justifyItems={"center"}
                  justifyContent={"center"}
                  gridArea={{
                    base: "3 / 1 / 4 / 2",
                    lg: "1 / 3 / 2 / 4",
                  }}
                  minH={{ base: "auto", lg: "420px", "2xl": "365px" }}
                  pe="20px"
                  pb={{ base: "100px", lg: "20px" }}
                />
              )}
              {isErrorDeviceImages && <FormErrorMessage>{isErrorDeviceImages as string}</FormErrorMessage>}
            </Card>

            <Button
              type="submit"
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={(e) => {
                //console.log("submit button clicked!");
                //console.log("is valid" + formik.isValid);
                //console.log("is valid" + JSON.stringify(formik.errors));
                formik.handleSubmit();
              }}
              disabled={isCreatingDevice}
            >
              {isCreatingDevice ? "Adding..." : "Add The Device"}
            </Button>
          </FormControl>
          <Flex direction={{ base: "row", md: "row" }} justifyContent={"space-between"}></Flex>
        </Card>
      </Box>
      {
        <ImageModal
          isOpen={isOpen}
          onClose={() => {
            setSelectedImage(null);
            onClose();
          }}
          title={selectedImage?.name}
          url={selectedImage?.url}
        />
      }
    </>
  );
}
export { AddDevice };
