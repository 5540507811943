import {
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
} from "@chakra-ui/react";
import KeyValueInput from "components/key-value-input/key-value-input";
import useReportTemplates from "hooks/data-hooks/useReportTemplates";
import * as React from "react";
import { useState } from "react";
import { toPascalCase } from "utilities/utilities";

export interface IReportInfoTabProps {
  createReportInput: CreateServiceReportInput;
  onCreateReportInputChange: (newValues: CreateServiceReportInput) => void;
  costDetails: CostDetails;
  onCostDetailsChanged: (newCostDetails: CostDetails) => void;
}

// Report Status Options (Used As Options For Select In Modal)
const reportStatusOptions = ["INITIAL", "In Progress", "Finished"];

export default function ReportInfoTab(props: IReportInfoTabProps) {
  const {
    createReportInput,
    onCreateReportInputChange,
    costDetails,
    onCostDetailsChanged,
  } = props;
  return (
    <>
      <Flex direction={{ sm: "column", xl: "row" }} gap="15px" my="5px">
        <FormControl>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            mb="8px"
          >
            Nex Visit Plan :
          </FormLabel>
          <Input
            mb="24px"
            type={"date"}
            required={true}
            isRequired={true}
            pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z"
            value={
              createReportInput.next_visit_plan.slice(0, 10) ??
              new Date().toISOString().slice(0, 10)
            }
            min={new Date().toISOString().slice(0, 10)}
            onChange={(e) => {
              if (!e.target.value) {
                return;
              }
              const [y, m, d] = e.target.value.split("-");
              const iso8601Date = new Date(
                Number(y),
                Number(m) - 1,
                Number(d) + 1
              ).toISOString();
              onCreateReportInputChange({
                ...createReportInput,
                next_visit_plan: iso8601Date,
              });
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            mb="8px"
          >
            Report Status :
          </FormLabel>
          <Select
            value={createReportInput.report_status}
            onChange={(e) => {
              onCreateReportInputChange({
                ...createReportInput,
                report_status: e.target.value as ReportStatus,
              });
            }}
            placeholder="Select Report Status"
          >
            {reportStatusOptions.map((y) => (
              <option value={y}>{toPascalCase(y)}</option>
            ))}
          </Select>
        </FormControl>
      </Flex>
      <FormControl>
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          mb="8px"
        >
          Cost Details :
        </FormLabel>
        <KeyValueInput
          data={costDetails}
          onChange={onCostDetailsChanged}
          keyPlaceholder={"Item"}
          valuePlaceholder={"Price"}
        />
      </FormControl>
    </>
  );
}
