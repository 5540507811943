import { Grid, GridItem, Text } from "@chakra-ui/react";

interface DeviceInfoTabProps {
  device?: Device;
}
const DeviceInfoTab = (props: DeviceInfoTabProps) => {
  const { device } = props;
  if (device == null) {
    return <>Device Not Exist! (maybe there are some error)</>;
  }
  return (
    <Grid gap={4}>
      {/* Name */}
      <GridItem colSpan={4}>
        <Text as="b">Name : </Text>
        {device.device_name ? device.device_name : "None"}
      </GridItem>
      {/* Name */}
      <GridItem colSpan={2}>
        <Text as="b">Type : </Text>
        {device.device_type ? device.device_type : "None"}
      </GridItem>
      {/* Group */}
      <GridItem colSpan={2}>
        <Text as="b">Group : </Text>
        {device.group.group_name ? device.group.group_name : "None"}
      </GridItem>
      {/* Serial Number */}
      <GridItem colSpan={2}>
        <Text as="b">Serial Number : </Text>
        {device.serial_number ? device.serial_number : "None"}
      </GridItem>
      {/* User (Device Owner)*/}
      <GridItem colSpan={2}>
        <Text as="b">Device Owner : </Text>
        {device.user.name ? device.user.name : "Unknown"}
      </GridItem>
      {/* Manufacturing Date */}
      <GridItem colSpan={2}>
        <>
          <Text as="b">Manufacturing Date : </Text>
          {device.manufacturing_date ? new Date(device.manufacturing_date).toDateString() : "None"}
        </>
      </GridItem>
      <GridItem colSpan={2}>
        <>
          <Text as="b">Startup Date : </Text>
          {device.startup_date ? new Date(device.startup_date).toDateString() : "None"}
        </>
      </GridItem>
      {/* <GridItem colSpan={4}>
      <Center>
        <Divider
          w={"80%"}
          borderColor={"#000000"}
          orientation="horizontal"
        />
      </Center>
    </GridItem> */}
      {/* Model */}
      <GridItem colSpan={2}>
        <Text as="b">Device Model : </Text> {device.device_model ?? "None"}
        {/* // TODO : Add Expand  */}
      </GridItem>
      {/* Type*/}
      <GridItem colSpan={2}>
        <Text as="b">Device Type : </Text> {device.device_type ?? "None"}
      </GridItem>
      {/* Work Rate*/}
      <GridItem colSpan={2}>
        <Text as="b">Duty Cycle : </Text> {device.work_rate ?? "None"}
      </GridItem>
      {device.need_kw && (
        <GridItem colSpan={2}>
          <Text as="b">Power(kw) : </Text> {device.work_power ?? "None"}
        </GridItem>
      )}
      <GridItem colSpan={2}>
        <Text as="b">Part No : </Text> {!device.part_no || device.part_no == "" ? "None" : device.part_no}
      </GridItem>
      <GridItem colSpan={2}>
        <Text as="b">Max Pressure: </Text> {device.max_pressure ?? "None"}
      </GridItem>
      <GridItem colSpan={2}>
        <Text as="b">Load Hours : </Text> {device.load_hours ?? "None"}
      </GridItem>
      <GridItem colSpan={2}>
        <Text as="b">Run Hours : </Text> {device.run_hours ?? "None"}
      </GridItem>
    </Grid>
  );
};

export default DeviceInfoTab;
