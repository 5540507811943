import { useMutation, useQuery } from "react-query";
import axios, { AxiosError } from "axios";
import { PagedResult } from "common/models";

const fetchCustomers = async (
    page?: number,
    pageSize?: number
) => {
    return (
        await axios.get<PagedResult<Customer>>(
            'user/client_profile/',
            {
                params: {
                    page_size: pageSize,
                    page: page,
                },
            }
        )
    ).data;
};

/**
 *
 */
interface IUseCustomersProps {
    page?: number;
    pageSize?: number;
    onLoadCustomersSuccess?: (data: PagedResult<Customer>) => void;
}

/**
 * used to get system customers
 * @returns
 */
const useCustomers = (props: IUseCustomersProps) => {
    // deconstruction props
    const {
        page,
        pageSize,
        onLoadCustomersSuccess,
    } = props;

    // data fetching useQuery hook (used to fetch devices for customers table)
    const {
        data: customers,
        isLoading: isLoadingCustomers,
        isError: isErrorCustomers,
        error: loadingCustomersError,
    } = useQuery<PagedResult<Customer>, AxiosError<any>>(
        ["customers", page, pageSize],
        () => fetchCustomers(page, pageSize),
        {
            keepPreviousData: true,
            onSuccess: onLoadCustomersSuccess,
        }
    );

    // return hooks result
    return {
        customers,
        isLoadingCustomers,
        isErrorCustomers,
        loadingCustomersError
    };
};

export default useCustomers;