import {
  Box,
  Flex,
  Text,
  Button,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineRollback } from "react-icons/ai";
import DeviceProblemWidget from "components/device-problem-widget/device-problem-widget";
import useDeviceProblem from "hooks/data-hooks/useDeviceProblem";
interface ProblemProps {
  problemId?: string;
}
const Problem = (props: ProblemProps) => {
  const { problemId } = useParams();
  const navigate = useNavigate();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  //const [isLoading, setIsLoading] = useState(true);
  //const [problem, setProblem] = useState<Problem>(undefined);
  
  const extractedProblemId = problemId ?? props.problemId;
  // const {isLoading, isError,error,deviceProblem} = useDeviceProblem(extractedProblemId);
  // /*useEffect(() => {
  //   if (!extractedProblemId || extractedProblemId == "") {
  //     return;
  //   }
  //   getProblem(setProblem, setIsLoading, extractedProblemId);
  // }, []);
  // if (!extractedProblemId || extractedProblemId == "") {
  //   return <>Can't Featch Problem!</>;
  // }*/
  // if (isLoading) {
  //   return <>Loading...</>;
  // }
  // if(isError){
  //   return <>Some Error Happen!</>
  // }
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex
        align="center"
        gap={"10px"}
        direction={{ base: "column", md: "column" }}
      >
        <Card
          flexDirection="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Flex
            px="25px"
            mb="8px"
            justifyContent="space-between"
            align="center"
          >
            <Text
              color={textColor}
              fontSize="22px"
              mb="4px"
              fontWeight="700"
              lineHeight="100%"
            >
              Problem
            </Text>
            <Stack direction="row" spacing={4}>
              <Button
                leftIcon={<AiOutlineRollback />}
                textColor={textColor}
                onClick={(e) => navigate(-1)}
              >
                Go Back
              </Button>
            </Stack>
          </Flex>
          <DeviceProblemWidget problemId={extractedProblemId} />
        </Card>
      </Flex>
    </Box>
  );
};

export default Problem;
