import { Icon } from "@chakra-ui/react";
import { MdHome } from "react-icons/md";
import { AddDevice } from "views/customer/add-device/add-device";
import { CreateOrder } from "views/customer/create-order/create-order";
import { CreateOrderContact } from "views/customer/add-order-contact/add-order-contact";
import MainDashboard from "views/customer/home-page/home-page";
import { Profile } from "views/customer/profile/profile";
import DeviceHistory from "views/technical/device-history/device-history";
import { Navigate } from "react-router-dom";
import WelcomePage from "views/customer/welcome-page";
import PrintOrderPage from "components/order-to-print-page";
import PrintDevicePage from "components/print-device-page";
import Survey from "views/customer/survey/survey";

// Auth Imports
const routes = [
  {
    name: "Home Page",
    layout: "/client",
    path: "",
    sideBar: true,
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <Navigate to="home" replace={true} />,
  },
  {  
    sideBar:true,
    name: "Print Order",
    layout: "/client",
    path: "/survey",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <Survey />,
  },
  {  
    sideBar:true,
    name: "Print Order",
    layout: "/client",
    path: "/print-order",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <PrintOrderPage />,
  },
  {  
    sideBar:true,
    name: "Print Device",
    layout: "/client",
    path: "/print-device",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <PrintDevicePage />,
  },
  {
    name: "Overview",
    layout: "/client",
    path: "/overview",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
  },
  {
    sideBar:true,
    name: "Welcome",
    layout: "/client",
    path: "/home",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <WelcomePage/>
  },
  {
    name: "Create new order",
    layout: "/client",
    path: "/create-new-order",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <CreateOrder />,
  },
  {
    name: "Add new  device",
    layout: "/client",
    path: "/add-device",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <AddDevice />,
  },
  {
    sideBar: true,
    name: "user profile",
    layout: "/client",
    path: "/profile",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <Profile />,
  },
  {
    sideBar: true,
    name: "Customers",
    layout: "/client",
    path: "/device-history/:tsgId",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <DeviceHistory />,
  },
  {
    name: "Create Order Contact",
    layout: "/client",
    path: "/create-order-contact",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <CreateOrderContact />,
  }
];

export default routes;
