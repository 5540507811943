import {
  Box,
  Flex,
  Text,
  Button,
  useColorModeValue,
  Stack,
  Grid,
  GridItem,
  Divider,
  Center,
  Image,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import { PagedResult } from "common/models";
import { AiOutlineRollback } from "react-icons/ai";
import Card from "components/card/Card";
import DeviceProblemWidget from "components/device-problem-widget/device-problem-widget";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useDeviceTsgHistory from "hooks/data-hooks/useDeviceTsgHistory";
import { TableCardSkelton } from "components/skeltons/table-card-skelton";

const DeviceHistory = () => {
  const { tsgId: troubleShootingGuideId } = useParams();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const navigate = useNavigate();
  const {
    deviceTsgHistory,
    isLoadingTsgHistory,
    isErrorTsgHistory,
    lopdaingDeviceTsgHistoryError,
  } = useDeviceTsgHistory(troubleShootingGuideId);
  if (!troubleShootingGuideId || troubleShootingGuideId == "") {
    return (
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        There Are No Trouble Shooting Guide For This Device!
      </Box>
    );
  }
  return isLoadingTsgHistory ? (
    <TableCardSkelton />
  ) : (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex
        align="center"
        gap={"10px"}
        direction={{ base: "column", md: "column" }}
      >
        <Card
          flexDirection="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Flex
            px="25px"
            mb="8px"
            justifyContent="space-between"
            align="center"
          >
            <Text
              color={textColor}
              fontSize="22px"
              mb="4px"
              fontWeight="700"
              lineHeight="100%"
            >
              Device History (Previous Orders) :
            </Text>
            <Stack direction="row" spacing={4}>
              <Button
                leftIcon={<AiOutlineRollback />}
                textColor={textColor}
                onClick={(e) => navigate(-1)}
              >
                Go Back
              </Button>
            </Stack>
          </Flex>
          {!deviceTsgHistory?.results ||
          deviceTsgHistory.results.length == 0 ? (
            <Empty />
          ) : (
            <Grid gap={6}>
              {deviceTsgHistory.results[0].problems.map((i) => (
                <GridItem
                  mb={"25px"}
                  maxHeight={"100%"}
                  maxWidth={"100%"}
                  key={i}
                >
                  {/* n<Problem problemId={i} /> */}
                  <DeviceProblemWidget problemId={i} />
                  <GridItem mt={"10px"}>
                    <Center>
                      <Divider
                        w={"80%"}
                        borderColor={"black"}
                        orientation="horizontal"
                        borderRadius={"3px"}
                        //   color={"black"}
                        //bgColor={"black"}
                        borderWidth={"1px"}
                      />
                    </Center>
                  </GridItem>
                </GridItem>
              ))}
            </Grid>
          )}
        </Card>
      </Flex>
    </Box>
  );
};
const Empty = () => (
  <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
    <div>
      There Are No Active Or Previous{" "}
      <Link style={{ color: "blue" }} to={"./orders"}>
        <u>Orders</u>
      </Link>{" "}
      For This Devcie.
    </div>
  </Box>
);
export default DeviceHistory;
