import Card from "components/card/Card";
import { Box, Flex, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Assets ;
import { useState, useEffect } from "react";
import { TableCardSkelton } from "components/skeltons/table-card-skelton";
import DevicesTable from "components/data-tables/devices-table";
import { PaginationState } from "common/models";
import useDevices from "hooks/data-hooks/useDevices";
import { orderStateOptions } from "consts/const";
import useOrders from "hooks/data-hooks/useOrders";
import OrdersTable from "components/data-tables/orders-table";
import AdminPendingOrdersTable from "components/data-tables/admin-pending-orders-table";
export default function HomePage() {
  // pagination states
  const [devicesPageState, setDevicesPageState] = useState<PaginationState>({
    page: 1,
    perPage: 5,
    count: 0,
  });

  const [ordersPageState, setOrdersPageState] = useState<PaginationState>({
    page: 1,
    perPage: 5,
    count: 0,
  });

  const [initializedOrdersPageState, setInitializedOrdersPageState] = useState<PaginationState>({
    page: 1,
    perPage: 5,
    count: 0,
  });

  // filter order states
  const [selectedOrderStates, setSelectedOrderStates] = useState(["ACCEPTED", "REJECTED", "DELETE", "CLOSED"]);
  const [selectedClient, setSelectedClient] = useState<ClientLite>(undefined);

  // fetching date using useDevice custom hook
  const { devices, isLoadingDevices, isErrorLoadingDevices, loadingDevicesError } = useDevices({
    actor: "admin",
    page: devicesPageState.page,
    pageSize: devicesPageState.perPage,
    onLoadDevicesSuccess: (data) => setDevicesPageState({ ...devicesPageState, count: data.count }),
  });

  const { orders, isLoadingOrders, isErrorOrders, loadingOrdersError, refetchOrders } = useOrders({
    page: ordersPageState.page,
    pageSize: ordersPageState.perPage,
    orderStates: selectedOrderStates,
    clientId: selectedClient?.id,
    onSuccess: (data) => setOrdersPageState({ ...ordersPageState, count: data.count }),
  });

  const {
    orders: initializedOrders,
    isLoadingOrders: isLoadingInitializedOrders,
    isErrorOrders: isErrorInitializedOrders,
    loadingOrdersError: loadingInitializedOrdersErrors,
    refetchOrders: refetchInitializedOrders,
  } = useOrders({
    customQueryKeyPrefix: "InitializedOrders",
    page: initializedOrdersPageState.page,
    pageSize: initializedOrdersPageState.perPage,
    orderStates: ["INITIALIZED"],
    clientId: undefined,
    onSuccess: (data) => setInitializedOrdersPageState({ ...initializedOrdersPageState, count: data.count }),
  });

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex align="center" gap={"10px"} direction={{ base: "column", md: "column" }}>
        {/* 1 - Orders Table */}
        {isLoadingOrders ? (
          <TableCardSkelton />
        ) : isErrorOrders ? (
          <TableCardSkelton
            message={loadingOrdersError?.response?.data?.message ?? "Un Expected Error Happen While Loading Orders!"}
            isErrorSkelton={true}
          />
        ) : (
          <OrdersTable
            selectedOrderStates={selectedOrderStates}
            setSelectedOrderStates={setSelectedOrderStates}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
            tableData={
              orders?.results?.map((r) => ({
                id: r.id,
                title: r.title,
                state: r.state,
                description: r.description,
                client: r.client, //.name,
                device: r.device.name, //.name,
                order_number: r.order_number,
                order_contact: r.order_contact,
                technical: r.technical,
                fullObject: r,
              })) || []
            }
            pagination={ordersPageState}
            setPagination={setOrdersPageState}
            isLoading={isLoadingOrders}
            viewAllOrdersRoute={"../orders"}
            hasPagination={false}
          />
        )}

        {/* 2 - Initialized Table */}
        {isLoadingInitializedOrders ? (
          <TableCardSkelton />
        ) : isErrorInitializedOrders ? (
          <TableCardSkelton
            message={
              loadingInitializedOrdersErrors?.response?.data?.message ??
              "Un Expected Error Happen While Loading Initialized Orders!"
            }
            isErrorSkelton={true}
          />
        ) : (
          <AdminPendingOrdersTable
            onOrderUpdated={() => {
              refetchOrders();
              refetchInitializedOrders();
            }}
            tableData={
              initializedOrders?.results.map((r) => ({
                title: r.title,
                state: r.state,
                description: r.description,
                client: r.client.name,
                device: r.device.name,
                order_number: r.order_number,
                order_contact: r.order_contact.map((t) => t.contact_name),

                technical: r?.technical?.map((t) => t.name),
                id: r.id,
              })) || []
            }
            pagination={initializedOrdersPageState}
            setPagination={setInitializedOrdersPageState}
            isLoading={isLoadingInitializedOrders}
            hasPagination={false}
          />
        )}

        {/* 3 - Devices (Machines Table) */}
        {isLoadingDevices ? (
          <TableCardSkelton />
        ) : isErrorLoadingDevices ? (
          <TableCardSkelton
            message={loadingDevicesError?.response?.data?.message ?? "Un Expected Error Happen While Loading Devices!"}
            isErrorSkelton={true}
          />
        ) : (
          <DevicesTable
            tableData={
              devices?.results?.map((r) => ({
                image: r.device_images?.at(0)?.image,
                name: r.device_name,
                type: r.device_type,
                model: r.device_model,
                number_of_orders: r.order?.length ?? 0,
                tsgId: r.troubleshooting_guid?.id,
                fullObject: r,
              })) || []
            }
            pagination={devicesPageState}
            setPagination={setDevicesPageState}
            isLoading={isLoadingDevices}
            viewAllMachinesRoute={"../machines"}
            hasPagination={false}
          />
        )}
      </Flex>
    </Box>
  );
}
