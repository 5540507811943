import axios from "axios";
import { useMutation } from "react-query";

const createReport = async (data: CreateServiceReportInput) => {
    return (await axios.post<ServiceReport>("services/service-report/", data))
        .data;
};

const updateOrderServiceDetails = async (serviceDetailsId: string, reportId: string, costDetails: CostDetails) => {
    return (await axios.patch<ServiceDetail>(`services/service-details/${serviceDetailsId}`, {
        report_id: reportId,
        cost_details: costDetails
    } as CreateServiceDetailInput)).data;
};

interface IUseReportSubmitterProps {
    serviceDetails: ServiceDetail,
    costDetails: CostDetails,
    onError?: () => void;
    onSuccess?: () => void;
}
/**
 *
 */
const useReportSubmitter = (props: IUseReportSubmitterProps) => {
    const {
        serviceDetails,
        costDetails,
        onError,
        onSuccess,
    } = props;
    //1- create report using services/service-report
    //2- above endpoint will return report has id
    // 3- i'll update service details using patch requesy using services/service-details/ 
    // to update report_id and cost_details
    const createReportPipeline = (createReportInput: CreateServiceReportInput) =>
        createReport(createReportInput).then(
            (report) => {
                return updateOrderServiceDetails(serviceDetails.id, report.id, costDetails).then((serviceDetails) => {
                    //console.log("Update Service Details Success : " + JSON.stringify(serviceDetails))
                })
            }
        );
    const {
        mutate: create,
        isLoading: isCreatingReport,
        isError: isErrorCreatingReport,
    } = useMutation(createReportPipeline, {

        onError: onError,
        onSuccess: onSuccess,
    });
    return { create, isCreatingReport, isErrorCreatingReport };
    /*createOrderServiceDetails(createServiceDetailsInput).then((serviceDetail));
    await createTroubleshootingGuide(tsgId, {
      problem_selected: problemSelected,
    });
    const orderProblemImages = await createOrderProblemImages(
      orderProblemImagesCreateInput
    );
    await createOrder({
      ...createOrderInput,
      service_details: serviceDetail.id,
      problem_images:orderProblemImages.problem_images
    } as CreateOrderInput);*/
};

export default useReportSubmitter;