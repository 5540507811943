import { Box, Divider, Text } from "@chakra-ui/react";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { hideSideBar } from "store/sideBarReducer";
import OrderImagesTab from "./modals/order-modal/order-modal.tabs/order-images-tab";
import OrderInfoTab from "./modals/order-modal/order-modal.tabs/order-info-tab";

export interface IPrintOrderPageProps {}

export default function PrintOrderPage(props: IPrintOrderPageProps) {
  const { state } = useLocation();
  useEffect(() => {
    if (state != null) {
      window.print();
    }
  }, []);
  if (state == null) {
    return <>Order Is Null</>;
  }
  const order = state as Order;
  return (
    <Box sx={{ paddingTop: "100px" }}>
      <Text as="b" fontSize={"20px"}>
        Order Info
      </Text>
      <OrderInfoTab order={order} />
      <Divider sx={{ marginBlock: "20px", borderWidth: "3px", borderColor: "black" }} />
      <Text as="b" fontSize={"20px"}>
        Order Images
      </Text>
      <OrderImagesTab images={order.problem_images} />
    </Box>
  );
}
